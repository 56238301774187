import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../components/UserContext';

const SignIn = () => {
    const navigate = useNavigate();
    const [loginId, setLoginId] = useState('');
    const [loginPw, setLoginPw] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { login } = useContext(UserContext);

    const handleLogin = async (e) => {
        e.preventDefault();

        if (!loginId || !loginPw) {
            setErrorMessage('아이디와 비밀번호를 모두 입력해주세요.');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ loginId, loginPw }),
            });

            const data = await response.json();

            if (data.code.startsWith("0")) {
                alert(data.data);
                return;
            }

            const { accessToken, refreshToken, userId, academyId, roles, status, passwordChangeRequired } = data.data;

            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('userId', userId);
            localStorage.setItem('academyId', academyId);
            localStorage.setItem('roles', JSON.stringify(roles));
            localStorage.setItem('status', status);

            login(roles, userId, academyId);

            if (passwordChangeRequired) {
                if (window.confirm('비밀번호를 변경한지 3개월이 지났습니다. 지금 바로 비밀번호를 변경하시겠습니까?')) {
                    navigate('/my-page#password-change');
                } else {
                    navigate('/dashboards');
                }
            } else {
                navigate('/dashboards');
            }
        } catch (error) {
            console.error('Login failed', error);
            setErrorMessage('로그인에 실패했습니다.');
        }
    };

    return (
        <div>
            <div className="h-16 bg-gray-100"></div>
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-lg shadow-lg">
                    <h2 className="text-2xl font-bold text-center">로그인</h2>
                    {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}
                    <form onSubmit={handleLogin} className="space-y-6">
                        <div>
                            <label htmlFor="loginId" className="block text-sm font-medium text-gray-700">아이디</label>
                            <input
                                autoComplete="off"
                                onPaste={(e) => e.preventDefault()}  // Prevent paste
                                type="text"
                                id="loginId"
                                value={loginId}
                                onChange={(e) => setLoginId(e.target.value)}
                                required
                                className="w-full p-2 mt-1 border border-gray-300 rounded-lg"
                            />
                        </div>
                        <div>
                            <label htmlFor="loginPw" className="block text-sm font-medium text-gray-700">비밀번호</label>
                            <input
                                autoComplete="off"
                                onPaste={(e) => e.preventDefault()}  // Prevent paste
                                type="password"
                                id="loginPw"
                                value={loginPw}
                                onChange={(e) => setLoginPw(e.target.value)}
                                required
                                className="w-full p-2 mt-1 border border-gray-300 rounded-lg"
                            />
                        </div>
                        <button type="submit" className="w-full p-2 font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-700">로그인</button>
                    </form>
                    <div className="space-y-4">
                        <p className="text-center">
                            아직 아이디가 없으신가요?
                            <br />
                            <Link to="/sign-up" className="text-blue-500 hover:underline">학원 등록하러 가기</Link>
                        </p>
                        <p className="text-center">
                            도움이 필요하신가요?
                            <br />
                            <Link to="/help/id" className="text-blue-500 hover:underline">아이디 찾기</Link> 또는 <Link to="/help/pw" className="text-blue-500 hover:underline">비밀번호 찾기</Link>
                        </p>
                        <p className="text-center">
                            체험기간이 종료되었나요?
                            <br />
                            <Link to="/end-trial" className="text-blue-500 hover:underline">정규회원으로 전환하기</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
