import React, { useState, useEffect } from 'react';
import { UserStatus } from '../constants/Constants';
import { useNavigate, Link } from 'react-router-dom';
import UseFetchToken from "../hooks/UseFetchToken";

const Students = () => {

    const navigate = useNavigate();

    const [students, setStudents] = useState([]);
    const [pageInfo, setPageInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDetailedSearch, setShowDetailedSearch] = useState(false);
    const [searchParams, setSearchParams] = useState({
        page: 1,
        size: 10,
        name: '',
        address: '',
        attendanceId: '',
        schoolName: '',
        parentName: '',
        status: '',
        schoolGrade: '',
        prop: 'name',
        dir: 'asc',
    });
    const [inputParams, setInputParams] = useState({
        name: '',
        address: '',
        attendanceId: '',
        schoolName: '',
        parentName: '',
        status: '',
        schoolGrade: '',
        prop: 'name',
        dir: 'asc',
    });

    const fetchStudents = async (params) => {
        setLoading(true);
        setError(null);

        const queryString = new URLSearchParams(params).toString();

        try {
            const data = await UseFetchToken(`/api/students?${queryString}`, {
                method: 'GET',
            });

            if (data) {
                setStudents(data.students);
                setPageInfo(data.pageInfo);
            }
        } catch (error) {
            console.error('Error:', error);
            setError('학생 목록을 불러오는 중 오류가 발생했습니다.');
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchStudents(searchParams);
    }, [searchParams]);

    if (loading) {
        return <div className="flex items-center justify-center h-screen">Loading...</div>;
    }

    if (error) {
        return <div className="flex items-center justify-center h-screen text-red-500">{error}</div>;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputParams((prevParams) => ({
            ...prevParams,
            [name]: value,
        }));
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setSearchParams((prevParams) => ({
            ...prevParams,
            ...inputParams,
            page: 1,
        }));
        if (showDetailedSearch) {
            setShowDetailedSearch(true);
        }
    };

    const handlePageChange = (newPage) => {
        setSearchParams((prevParams) => ({
            ...prevParams,
            page: newPage,
        }));
    };

    const handlePageSetChange = (direction) => {
        const startPage = Math.floor((pageInfo.page - 1) / 10) * 10 + 1;
        const newPage = direction === 'first' ? 1 :
            direction === 'last' ? pageInfo.lastPage :
                direction === 'prev' ? Math.max(1, startPage - 10) :
                    Math.min(pageInfo.lastPage, startPage + 10);

        setSearchParams((prevParams) => ({
            ...prevParams,
            page: newPage,
        }));
    };

    const renderPagination = () => {
        const startPage = Math.floor((pageInfo.page - 1) / 10) * 10 + 1;
        const endPage = Math.min(startPage + 9, pageInfo.lastPage);
        const pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        const isFirstSet = pageInfo.page <= 10;
        const isLastSet = endPage === pageInfo.lastPage;

        return (
            <div className="mt-4 flex justify-center space-x-2">
                <button
                    onClick={() => handlePageSetChange('first')}
                    disabled={isFirstSet}
                    className={`px-2 py-1 bg-blue-500 text-white rounded ${isFirstSet && 'opacity-50 cursor-not-allowed'}`}
                >
                    &lt;&lt;
                </button>
                <button
                    onClick={() => handlePageSetChange('prev')}
                    disabled={isFirstSet}
                    className={`px-2 py-1 bg-blue-500 text-white rounded ${isFirstSet && 'opacity-50 cursor-not-allowed'}`}
                >
                    &lt;
                </button>
                {pageNumbers.map((number) => (
                    <button
                        key={number}
                        onClick={() => handlePageChange(number)}
                        className={`px-2 py-1 ${number === pageInfo.page ? 'bg-blue-700 text-white' : 'bg-blue-500 text-white'} rounded`}
                    >
                        {number}
                    </button>
                ))}
                <button
                    onClick={() => handlePageSetChange('next')}
                    disabled={isLastSet}
                    className={`px-2 py-1 bg-blue-500 text-white rounded ${isLastSet && 'opacity-50 cursor-not-allowed'}`}
                >
                    &gt;
                </button>
                <button
                    onClick={() => handlePageSetChange('last')}
                    disabled={isLastSet}
                    className={`px-2 py-1 bg-blue-500 text-white rounded ${isLastSet && 'opacity-50 cursor-not-allowed'}`}
                >
                    &gt;&gt;
                </button>
            </div>
        );
    };

    const renderSchoolGradeOptions = () => {
        const grades = [
            { label: '초등1', value: 1 },
            { label: '초등2', value: 2 },
            { label: '초등3', value: 3 },
            { label: '초등4', value: 4 },
            { label: '초등5', value: 5 },
            { label: '초등6', value: 6 },
            { label: '중등1', value: 7 },
            { label: '중등2', value: 8 },
            { label: '중등3', value: 9 },
            { label: '고등1', value: 10 },
            { label: '고등2', value: 11 },
            { label: '고등3', value: 12 },
        ];
        return grades.map((grade) => (
            <option key={grade.value} value={grade.value}>{grade.label}</option>
        ));
    };

    const renderStatusOptions = () => {
        return UserStatus.values().map((status) => (
            <option key={status.name} value={status.name}>{status.value}</option>
        ));
    };

    const formatSchoolGrade = (grade) => {
        if (grade >= 1 && grade <= 6) {
            return `초등${grade}`;
        } else if (grade >= 7 && grade <= 9) {
            return `중등${grade - 6}`;
        } else if (grade >= 10 && grade <= 12) {
            return `고등${grade - 9}`;
        }
        return '';
    };

    return (
        <div>
            <div className="h-32"></div>
            <div className="p-6 max-w-full px-56 mx-auto">
                <div className="flex justify-between items-center mb-4">
                    <form onSubmit={handleSearchSubmit} className="flex items-center space-x-4">
                        <input
                            autoComplete="off"
                            onPaste={(e) => e.preventDefault()}  // Prevent paste
                            type="text"
                            name="name"
                            value={inputParams.name}
                            onChange={handleInputChange}
                            placeholder="학생이름"
                            className="p-2 border border-gray-300 rounded"
                        />
                        <select
                            name="prop"
                            value={inputParams.prop}
                            onChange={handleInputChange}
                            className="p-2 border border-gray-300 rounded"
                        >
                            <option value="name">정렬속성</option>
                            <option value="name">이름</option>
                            <option value="status">상태</option>
                            <option value="schoolName">학교 이름</option>
                            <option value="attendanceId">출석 아이디</option>
                        </select>
                        <select
                            name="dir"
                            value={inputParams.dir}
                            onChange={handleInputChange}
                            className="p-2 border border-gray-300 rounded"
                        >
                            <option value="asc">정렬방향</option>
                            <option value="asc">오름차순</option>
                            <option value="desc">내림차순</option>
                        </select>
                        <button type="submit" className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">검색</button>
                    </form>
                    <Link to="/students/new" className="text-white p-2 rounded bg-green-500 hover:bg-green-600">학생 등록</Link>
                </div>
                <button
                    type="button"
                    onClick={() => setShowDetailedSearch((prev) => !prev)}
                    className="bg-gray-200 text-gray-700 p-2 rounded w-full mb-4"
                >
                    상세검색
                </button>
                {showDetailedSearch && (
                    <form onSubmit={handleSearchSubmit} className="mb-4 space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <input
                                    autoComplete="off"
                                    onPaste={(e) => e.preventDefault()}  // Prevent paste
                                    type="text"
                                    name="address"
                                    value={inputParams.address}
                                    onChange={handleInputChange}
                                    placeholder="주소"
                                    className="p-2 border border-gray-300 rounded w-full"
                                />
                            </div>
                            <div>
                                <input
                                    autoComplete="off"
                                    onPaste={(e) => e.preventDefault()}  // Prevent paste
                                    type="text"
                                    name="attendanceId"
                                    value={inputParams.attendanceId}
                                    onChange={handleInputChange}
                                    placeholder="출석 아이디"
                                    className="p-2 border border-gray-300 rounded w-full"
                                />
                            </div>
                            <div>
                                <input
                                    autoComplete="off"
                                    onPaste={(e) => e.preventDefault()}  // Prevent paste
                                    type="text"
                                    name="schoolName"
                                    value={inputParams.schoolName}
                                    onChange={handleInputChange}
                                    placeholder="학교 이름"
                                    className="p-2 border border-gray-300 rounded w-full"
                                />
                            </div>
                            <div>
                                <input
                                    autoComplete="off"
                                    onPaste={(e) => e.preventDefault()}  // Prevent paste
                                    type="text"
                                    name="parentName"
                                    value={inputParams.parentName}
                                    onChange={handleInputChange}
                                    placeholder="부모 이름"
                                    className="p-2 border border-gray-300 rounded w-full"
                                />
                            </div>
                            <div>
                                <select
                                    name="status"
                                    value={inputParams.status}
                                    onChange={handleInputChange}
                                    className="p-2 border border-gray-300 rounded w-full"
                                >
                                    <option value="">학생 상태 선택</option>
                                    {renderStatusOptions()}
                                </select>
                            </div>
                            <div>
                                <select
                                    name="schoolGrade"
                                    value={inputParams.schoolGrade}
                                    onChange={handleInputChange}
                                    className="p-2 border border-gray-300 rounded w-full"
                                >
                                    <option value="">학년 선택</option>
                                    {renderSchoolGradeOptions()}
                                </select>
                            </div>
                        </div>
                    </form>
                )}
                <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                    <tr>
                        <th className="py-2 px-4 border-b text-base font-bold bg-blue-500 text-white">출석번호</th>
                        <th className="py-2 px-4 border-b text-base font-bold bg-blue-500 text-white">이름</th>
                        <th className="py-2 px-4 border-b text-base font-bold bg-blue-500 text-white">학교</th>
                        <th className="py-2 px-4 border-b text-base font-bold bg-blue-500 text-white">연락처</th>
                        <th className="py-2 px-4 border-b text-base font-bold bg-blue-500 text-white">학생상태</th>
                        <th className="py-2 px-4 border-b text-base font-bold bg-blue-500 text-white">가입일</th>
                        <th className="py-2 px-4 border-b text-base font-bold bg-blue-500 text-white">개인정보</th>
                        <th className="py-2 px-4 border-b text-base font-bold bg-blue-500 text-white">학습통계</th>
                    </tr>
                    </thead>
                    <tbody>
                    {students.length === 0 ? (
                        <tr>
                            <td colSpan="6" className="py-4 text-center">아직 학생이 등록되지 않았습니다. 학생을 등록해주세요.</td>
                        </tr>
                    ) : (
                        students.map((student) => {
                            const contact = student.phone ? `${student.phone}` : `${student.parentPhone} (부모)`;
                            const date = new Date(student.createdDateTime);
                            const formattedDate = `${date.getFullYear()}년 ${('0' + (date.getMonth() + 1)).slice(-2)}월 ${('0' + date.getDate()).slice(-2)}일`;
                            const status = UserStatus.values().find((s) => s.name === student.status);

                            return (
                                <tr key={student.id} className="hover:bg-gray-100 cursor-pointer">
                                    <td className="py-2 px-4 border-b text-center">{student.attendanceId}</td>
                                    <td className="py-2 px-4 border-b text-center">{student.name}</td>
                                    <td className="py-2 px-4 border-b text-center">{`${student.schoolName} ${formatSchoolGrade(student.schoolGrade)}`}</td>
                                    <td className="py-2 px-4 border-b text-center">{contact}</td>
                                    <td className="py-2 px-4 border-b text-center">{status.value}</td>
                                    <td className="py-2 px-4 border-b text-center">{formattedDate}</td>
                                    <td className="py-2 px-4 border-b text-center">
                                        <button
                                            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                                            onClick={() => navigate(`/students/${student.id}`)}
                                        >개인정보</button>
                                    </td>
                                    <td className="py-2 px-4 border-b text-center">
                                        <button
                                            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                                            onClick={() => navigate(`/dashboards?studentId=${student.id}`)}
                                        >학습통계</button>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                    </tbody>
                </table>
                {renderPagination()}
            </div>
        </div>
    );
};

export default Students;
