import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UseFetchToken from "../hooks/UseFetchToken";

const Book = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { bookId } = useParams();
    const isEditing = Boolean(bookId);
    const wordsEndRef = useRef(null);
    const inputRefs = useRef([]);
    const toastId = useRef(null);
    const fileInputRef = useRef(null);

    const [count, setCount] = useState(0);
    const [bookData, setBookData] = useState({
        openToPublic: false,
        publisher: null,
        name: null,
        chapter: null,
        subject: null,
        schoolGrade: null,
    });

    const [words, setWords] = useState([{ korean: '', english: '' }]);
    const [showWordInput, setShowWordInput] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const maxWords = 100;

    const grades = [
        { label: '학년 선택', value: null },
        { label: '초등 1학년', value: 1 },
        { label: '초등 2학년', value: 2 },
        { label: '초등 3학년', value: 3 },
        { label: '초등 4학년', value: 4 },
        { label: '초등 5학년', value: 5 },
        { label: '초등 6학년', value: 6 },
        { label: '중등 1학년', value: 7 },
        { label: '중등 2학년', value: 8 },
        { label: '중등 3학년', value: 9 },
        { label: '고등 1학년', value: 10 },
        { label: '고등 2학년', value: 11 },
        { label: '고등 3학년', value: 12 },
    ];

    useEffect(() => {
        const fetchBook = async () => {
            setLoading(true);
            if (isEditing) {
                try {
                    const data = await UseFetchToken(`/api/books/${bookId}`, {
                        method: 'GET',
                    });
                    setBookData({
                        openToPublic: data.book.openToPublic,
                        publisher: data.book.publisher,
                        name: data.book.name,
                        chapter: data.book.chapter,
                        subject: data.book.subject,
                        schoolGrade: data.book.schoolGrade, // API로부터 받아온 값 사용
                    });
                    setWords(data.words.length > 0
                        ? data.words.map((word) => ({ korean: word.korean, english: word.english }))
                        : [{ korean: '', english: '' }]);
                } catch (error) {
                    console.error('Error fetching book:', error);
                }
            }
            setLoading(false);
        }
        fetchBook();
    }, [isEditing, bookId, count]);

    if (loading) {
        return <div className="flex items-center justify-center h-screen">Loading...</div>;
    }

    const renderSchoolGradeOptions = () => {
        return grades.map((grade) => (
            <option key={grade.value} value={grade.value}>{grade.label}</option>
        ));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBookData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleGradeChange = (e) => {
        const value = parseInt(e.target.value, 10);
        setBookData((prevState) => ({
            ...prevState,
            schoolGrade: value,
        }));
    };

    const handleWordChange = (index, e) => {
        const { name, value } = e.target;
        const regex = name === 'english' ? /^[A-Za-z]*$/ : /^[\u3131-\uD79D]*$/;

        if (!regex.test(value)) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(name === 'english' ? '영어로 입력해주세요' : '한글로 입력해주세요', {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            }
            return;
        }

        setWords((prevWords) => {
            const newWords = [...prevWords];
            newWords[index][name] = value;
            return newWords;
        });
    };

    const handleAddWord = () => {
        if (words.length < maxWords) {
            setWords((prevWords) => [...prevWords, { korean: '', english: '' }]);
            setTimeout(() => {
                const lastRef = inputRefs.current[inputRefs.current.length - 1]?.english;
                if (lastRef) {
                    lastRef.focus();
                    wordsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        } else {
            alert('단어는 100개를 넘길 수 없습니다.');
        }
    };

    const handleWordKeyDown = (e, index, field) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const isKorean = field === 'korean';
            const nextField = isKorean ? 'english' : 'korean';

            if (isKorean && index === words.length - 1) {
                handleAddWord();
            }

            setTimeout(() => {
                const nextIndex = isKorean ? index + 1 : index;
                const nextRef = inputRefs.current[nextIndex]?.[nextField];

                if (nextRef) {
                    nextRef.focus();
                    if (isKorean) {
                        nextRef.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    }
                }
            }, 100);
        }
    };

    const handleDeleteWord = (index) => {
        if (words.length > 1) {
            setWords((prevWords) => prevWords.filter((_, i) => i !== index));
            inputRefs.current = inputRefs.current.filter((_, i) => i !== index);
        } else {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    '최소 한 개의 단어가 필요합니다.',
                    {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                    });
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // bookData validation.
        if (bookData.publisher && bookData.publisher.length > 30) {
            alert('출판사는 30자를 넘을 수 없습니다.');
            return;
        }
        if (bookData.name && bookData.name.length > 30) {
            alert('교재명은 30자를 넘을 수 없습니다.');
            return;
        }
        if (bookData.chapter && bookData.chapter.length > 30) {
            alert('챕터는 30자를 넘을 수 없습니다.');
            return;
        }
        if (bookData.subject && bookData.subject.length > 30) {
            alert('주제는 30자를 넘을 수 없습니다.');
            return;
        }

        const method = isEditing ? 'PATCH' : 'POST';
        const url = isEditing ? `/api/books/${bookId}` : '/api/books';
        try {
            const data = await UseFetchToken(url, {
                method,
                body: JSON.stringify(bookData),
            });

            if (data) {
                alert(isEditing ? '교재 정보를 수정했습니다.' : '교재를 등록했습니다.');
                if (isEditing) {
                    setCount(count + 1);
                } else {
                    navigate(`/books/${data.bookId}`);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleAddWords = async () => {
        try {
            if (words.length === 0) {
                alert('단어를 추가해주세요.');
                return;
            }

            const validWords = words.filter(word => word.english && word.korean);
            if (validWords.length !== words.length) {
                alert('모든 단어 입력란을 채워주세요.');
                return;
            }

            const data = await UseFetchToken(`/api/books/${bookId}/words/json`, {
                method: 'PUT',
                body: JSON.stringify({ words: validWords }),
            });

            if (data) {
                alert('단어를 저장했습니다.');
                setWords([{ korean: '', english: '' }]);
                setCount(count + 1);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleDelete = async () => {
        if (!window.confirm('정말로 삭제하시겠습니까?')) {
            return;
        }

        try {
            const data = await UseFetchToken(`/api/books/${bookId}`, {
                method: 'DELETE',
            });

            if (data) {
                alert('교재를 삭제했습니다.');
                navigate('/books');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleInputFocus = (e) => {
        if (e.target.name === 'english') {
            e.target.setAttribute('lang', 'en');
        } else if (e.target.name === 'korean') {
            e.target.setAttribute('lang', 'ko');
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (!/\.(xls|xlsx)$/i.test(file.name)) {
                alert('엑셀 파일(.xls, .xlsx)만 업로드할 수 있습니다.');
                fileInputRef.current.value = '';
                return;
            }
            if (file.size > 1 * 1024 * 1024) { // 1MB
                alert('파일 크기는 1MB를 초과할 수 없습니다.');
                fileInputRef.current.value = '';
                return;
            }
            setSelectedFile(file);
        }
    };

    const handleFileUpload = async () => {
        if (!selectedFile) {
            alert('엑셀 파일을 선택해주세요.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const data = await UseFetchToken(`/api/books/${bookId}/words/excel`, {
                method: 'PUT',
                body: formData,
            });

            if (data) {
                alert('엑셀 파일의 단어를 저장했습니다.');
                setSelectedFile(null);
                setShowWordInput(true);
                fileInputRef.current.value = '';
                setCount(count + 1);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container mx-auto">
            <ToastContainer />
            <div className="h-32"></div>
            <form onSubmit={handleSubmit} className="space-y-4 p-6">
                <h2 className="text-2xl font-bold">교재 정보</h2>
                <div>
                    <label className="block mb-2">학년</label>
                    <select
                        name="schoolGrade"
                        value={bookData.schoolGrade}
                        onChange={handleGradeChange}
                        className="block w-full p-2 border border-gray-300 rounded"
                    >
                        {renderSchoolGradeOptions()}
                    </select>
                </div>
                <div>
                    <label className="block mb-2">교재명*</label>
                    <input
                        autoComplete="off"
                        onPaste={(e) => e.preventDefault()}
                        type="text"
                        name="name"
                        value={bookData.name}
                        onChange={handleInputChange}
                        className="block w-full p-2 border border-gray-300 rounded"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-2">출판사</label>
                    <input
                        autoComplete="off"
                        onPaste={(e) => e.preventDefault()}
                        type="text"
                        name="publisher"
                        value={bookData.publisher}
                        onChange={handleInputChange}
                        className="block w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div>
                    <label className="block mb-2">챕터</label>
                    <input
                        autoComplete="off"
                        onPaste={(e) => e.preventDefault()}
                        type="text"
                        name="chapter"
                        value={bookData.chapter}
                        onChange={handleInputChange}
                        className="block w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div>
                    <label className="block mb-2">주제</label>
                    <input
                        autoComplete="off"
                        onPaste={(e) => e.preventDefault()}
                        type="text"
                        name="subject"
                        value={bookData.subject}
                        onChange={handleInputChange}
                        className="block w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div>
                    <label className="block mb-2">공개여부*</label>
                    <input
                        type="checkbox"
                        name="openToPublic"
                        checked={bookData.openToPublic}
                        onChange={(e) => setBookData((prevState) => ({
                            ...prevState,
                            openToPublic: e.target.checked,
                        }))}
                        className="block"
                    />
                </div>
                <button type="submit" className="mt-4 bg-blue-500 text-white p-2 rounded">
                    {isEditing ? '수정' : '등록'}
                </button>
            </form>

            {isEditing && (
                <div className="mt-6">
                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={() => setShowWordInput(true)}
                            className={`bg-blue-500 text-white p-2 rounded ${showWordInput ? 'hidden' : ''}`}
                        >
                            단어 직접 입력
                        </button>
                        <button
                            type="button"
                            onClick={() => setShowWordInput(false)}
                            className={`bg-green-500 text-white p-2 rounded ${showWordInput ? '' : 'hidden'}`}
                        >
                            엑셀 파일 업로드
                        </button>
                    </div>

                    {showWordInput ? (
                        <div className="mt-6">
                            <h2 className="text-2xl font-bold">단어 목록</h2>
                            <div className="mt-4 space-y-4">
                                <div className="bg-white p-4 rounded shadow">
                                    {words.map((word, index) => (
                                        <div key={index} className="flex flex-col space-y-2 mb-4">
                                            <div className="flex justify-between items-center">
                                                <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="english"
                                                    value={word.english}
                                                    onChange={(e) => handleWordChange(index, e)}
                                                    placeholder="영어"
                                                    className="block w-1/2 p-2 border border-gray-300 rounded"
                                                    onKeyDown={(e) => handleWordKeyDown(e, index, 'english')}
                                                    ref={(el) => inputRefs.current[index] = { ...inputRefs.current[index], english: el }}
                                                    onFocus={handleInputFocus}
                                                    onClick={handleInputFocus}
                                                />
                                                <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="korean"
                                                    value={word.korean}
                                                    onChange={(e) => handleWordChange(index, e)}
                                                    placeholder="한글"
                                                    className="block w-1/2 p-2 border border-gray-300 rounded"
                                                    onKeyDown={(e) => handleWordKeyDown(e, index, 'korean')}
                                                    ref={(el) => inputRefs.current[index] = { ...inputRefs.current[index], korean: el }}
                                                    onFocus={handleInputFocus}
                                                    onClick={handleInputFocus}
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => handleDeleteWord(index)}
                                                    className={`ml-2 p-1 bg-red-500 text-white rounded ${words.length === 1 ? 'hidden cursor-not-allowed' : ''}`}
                                                    disabled={words.length === 1} // Disable delete button if only one word
                                                >
                                                    X
                                                </button>
                                            </div>
                                            {index === words.length - 1 && (
                                                <button
                                                    type="button"
                                                    onClick={handleAddWord}
                                                    className="bg-gray-200 text-gray-700 p-2 rounded text-lg font-bold"
                                                >
                                                    +
                                                </button>
                                            )}
                                        </div>
                                    ))}
                                    <div ref={wordsEndRef} />
                                </div>
                                <button
                                    type="button"
                                    onClick={handleAddWords}
                                    className="mt-4 bg-blue-500 text-white p-2 rounded"
                                >
                                    단어 목록 등록
                                </button>
                            </div>
                            <button
                                type="button"
                                onClick={handleDelete}
                                className="mt-6 bg-red-500 text-white p-2 rounded"
                            >
                                교재 삭제
                            </button>
                        </div>
                    ) : (
                        <div className="mt-6 leading-loose">
                            <div>
                                <h2 className="text-2xl font-bold">엑셀 파일 업로드 방법</h2>
                                <p className="mt-2">
                                <span className="text-xl font-bold">
                                    1. 엑셀 견본을 다운로드해주세요.<br/>
                                    <span className="my-2 block">
                                        <a
                                            href="/excels/words.xlsx"
                                            download
                                            className="bg-gray-300 text-gray-700 p-2 rounded inline-block"
                                        >
                                            엑셀 견본 다운로드
                                        </a>
                                    </span>
                                    2. 엑셀 파일을 등록할 단어들로 수정해주세요.<br/>
                                    3. 수정한 엑셀 파일을 업로드하고 아래의 [파일 제출] 버튼을 누르면 단어가 등록됩니다.<br/>
                                </span>
                                    <br/>
                                    <span className="text-lg font-bold">주의사항</span><br/>
                                    <span className="text-gray-600">
                                    *첫 줄의 english, korean 은 삭제하시면 안 됩니다.<br/>
                                    *단어는 최대 100개까지 등록할 수 있습니다.<br/>
                                    *기존에 등록된 단어는 삭제되니 주의해주세요.<br/>
                                    <span className="block leading-normal">
                                        *가급적 빈 줄이 없도록 단어들을 순서대로 채워주세요.<br/>
                                        &nbsp;단어들 사이에 빈 줄이 있으면 그 이후 단어들이 등록되지 않을 수 있습니다.<br/>
                                    </span>
                                    *파일 용량은 1MB를 초과할 수 없습니다.<br/>
                                    *엑셀 파일(*.xls, *.xlsx)만 가능합니다.<br/>
                                </span>
                                </p>
                                <div className="mt-4">
                                    <input
                                        type="file"
                                        accept=".xls,.xlsx"
                                        onChange={handleFileChange}
                                        className="block w-full text-gray-700 p-2 border border-gray-300 rounded"
                                        ref={fileInputRef}
                                    />
                                </div>
                                <button
                                    type="button"
                                    onClick={handleFileUpload}
                                    className="mt-4 bg-gray-500 text-white p-2 rounded"
                                >
                                    파일 제출
                                </button>
                            </div>
                            <button
                                type="button"
                                onClick={handleDelete}
                                className="mt-6 bg-red-500 text-white p-2 rounded"
                            >
                                교재 삭제
                            </button>
                        </div>
                    )}
                </div>
            )}
            <div className="h-32"></div>
        </div>
    );
};

export default Book;
