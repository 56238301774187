import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

const StudyWordTracing = ({ word, ignoreCase, onSubmit, nextSlide, slideVisible }) => {
    const [input, setInput] = useState("");
    const inputRef = useRef(null);

    useEffect(() => {
        if (slideVisible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [slideVisible]);

    const checkAnswer = (e) => {
        const correct = ignoreCase
            ? word.answer?.toLowerCase() === input.toLowerCase()
            : word.answer === input;

        if (correct) {
            toast.success("훌륭합니다!", {
                position: 'top-center',
                autoClose: 500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            onSubmit(word.id, input, e.key === "Enter");
            setTimeout(() => {
                nextSlide();
            }, 500);
        } else {
            toast.error("단어를 다시 확인해주세요", {
                position: 'top-center',
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleChange = (e) => {
        setInput(e.target.value);
        onSubmit(word.id, e.target.value, e.key === "Enter");
    }

    return (
        <div className="study-word-tracing flex flex-col items-center z-20">
            {word.answer && <div className="text-8xl font-bold pb-6">{word.answer}</div>}
            <div className="text-xl text-gray-500">{word.question}</div>
            <input
                ref={inputRef}
                type="text"
                className="mt-9 p-2 border rounded w-full max-w-lg text-center text-4xl font-bold"
                value={input}
                onClick={() => inputRef.current.focus()}
                // focus 되지 않을 때 이벤트 발생
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        checkAnswer(e);
                    }
                }}
            />
        </div>
    );
};

export default StudyWordTracing;
