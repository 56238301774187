const AcademyStatus = {
    PENDING: { name: "PENDING", value: "대기중" },
    VERIFIED: { name: "VERIFIED", value: "인증완료" },
    FORBIDDEN: { name: "FORBIDDEN", value: "차단됨" },
    WITHDRAWN: { name: "WITHDRAWN", value: "탈퇴함" },
    values() {
        return Object.values(this).filter((v) => typeof v === 'object');
    },
};

const BookStatus = {
    CREATED: { name: "CREATED", value: "생성" },
    DELETED: { name: "DELETED", value: "삭제" },
    values() {
        return Object.values(this).filter((v) => typeof v === 'object');
    },
};

const WordStatus = {
    CREATED: { name: "CREATED", value: "생성" },
    DELETED: { name: "DELETED", value: "삭제" },
    values() {
        return Object.values(this).filter((v) => typeof v === 'object');
    },
};

const StudyClassification = {
    PRACTICE: { name: "PRACTICE", value: "연습" },
    EXAM: { name: "EXAM", value: "시험" },
    values() {
        return Object.values(this).filter((v) => typeof v === 'object');
    },
};

const StudyStatus = {
    ASSIGNED: { name: "ASSIGNED", value: "숙제" },
    STARTED: { name: "STARTED", value: "시작" },
    SUBMITTED: { name: "SUBMITTED", value: "제출" },
    DELETED: { name: "DELETED", value: "삭제" },
    values() {
        return Object.values(this).filter((v) => typeof v === 'object');
    },
};

const StudyTarget = {
    ENGLISH: { name: "ENGLISH", value: "영어" },
    KOREAN: { name: "KOREAN", value: "한국어" },
    values() {
        return Object.values(this).filter((v) => typeof v === 'object');
    },
};

const StudyType = {
    TRACING: { name: "TRACING", value: "따라쓰기" },
    SELECT: { name: "SELECT", value: "선택형" },
    WRITING: { name: "WRITING", value: "입력형" },
    values() {
        return Object.values(this).filter((v) => typeof v === 'object');
    },
};

const RoleName = {
    ROLE_ADMIN: { name: "ROLE_ADMIN", value: "관리자" },
    ROLE_DIRECTOR: { name: "ROLE_DIRECTOR", value: "원장님" },
    ROLE_TEACHER: { name: "ROLE_TEACHER", value: "선생님" },
    ROLE_STUDENT: { name: "ROLE_STUDENT", value: "학생" },
    values() {
        return Object.values(this).filter((v) => typeof v === 'object');
    },
};

const UserStatus = {
    ACTIVE: { name: "ACTIVE", value: "일반회원" },
    WITHDRAWN: { name: "WITHDRAWN", value: "탈퇴" },
    PENDING: { name: "PENDING", value: "대기" },
    FORBIDDEN: { name: "FORBIDDEN", value: "차단" },
    TRIAL: { name: "TRIAL", value: "체험" },
    TRIAL_END: { name: "TRIAL_END", value: "체험종료" },
    values() {
        return Object.values(this).filter((v) => typeof v === 'object');
    },
};

export {
    AcademyStatus,
    BookStatus,
    WordStatus,
    StudyClassification,
    StudyStatus,
    StudyTarget,
    StudyType,
    RoleName,
    UserStatus,
};
