import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import UseFetchToken from "../hooks/UseFetchToken";

const Books = () => {

    const navigate = useNavigate();
    const [books, setBooks] = useState([]);
    const [pageInfo, setPageInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useState({
        page: 1,
        size: 30,
        keyword: '',
        year: '',
        privateOnly: false,
    });
    const [inputParams, setInputParams] = useState({
        keyword: '',
        year: '',
        privateOnly: false,
    });

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 30 }, (_, i) => currentYear - i);

    const fetchBooks = async (params) => {
        setLoading(true);
        const queryString = new URLSearchParams(params).toString();

        try {
            const data = await UseFetchToken(`/api/books?${queryString}`, {
                method: 'GET',
            });

            if (data) {
                setBooks(data.books);
                setPageInfo(data.pageInfo);
            }
        } catch (error) {
            console.error('Error:', error);
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchBooks(searchParams);
    }, [searchParams]);

    if (loading) {
        return <div className="flex items-center justify-center h-screen">Loading...</div>;
    }

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setInputParams((prevParams) => ({
            ...prevParams,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setSearchParams((prevParams) => ({
            ...prevParams,
            ...inputParams,
            page: 1,
        }));
    };

    const handlePageChange = (newPage) => {
        setSearchParams((prevParams) => ({
            ...prevParams,
            page: newPage,
        }));
    };

    const handlePageSetChange = (direction) => {
        const startPage = Math.floor((pageInfo.page - 1) / 10) * 10 + 1;
        const newPage = direction === 'first' ? 1 :
            direction === 'last' ? pageInfo.lastPage :
                direction === 'prev' ? Math.max(1, startPage - 10) :
                    Math.min(pageInfo.lastPage, startPage + 10);

        setSearchParams((prevParams) => ({
            ...prevParams,
            page: newPage,
        }));
    };

    const renderPagination = () => {
        const startPage = Math.floor((pageInfo.page - 1) / 10) * 10 + 1;
        const endPage = Math.min(startPage + 9, pageInfo.lastPage);
        const pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        const isFirstSet = pageInfo.page <= 10;
        const isLastSet = endPage === pageInfo.lastPage;

        return (
            <div className="mt-4 flex justify-center space-x-2">
                <button
                    onClick={() => handlePageSetChange('first')}
                    disabled={isFirstSet}
                    className={`px-2 py-1 bg-blue-500 text-white rounded ${isFirstSet && 'opacity-50 cursor-not-allowed'}`}
                >
                    &lt;&lt;
                </button>
                <button
                    onClick={() => handlePageSetChange('prev')}
                    disabled={isFirstSet}
                    className={`px-2 py-1 bg-blue-500 text-white rounded ${isFirstSet && 'opacity-50 cursor-not-allowed'}`}
                >
                    &lt;
                </button>
                {pageNumbers.map((number) => (
                    <button
                        key={number}
                        onClick={() => handlePageChange(number)}
                        className={`px-2 py-1 ${number === pageInfo.page ? 'bg-blue-700 text-white' : 'bg-blue-500 text-white'} rounded`}
                    >
                        {number}
                    </button>
                ))}
                <button
                    onClick={() => handlePageSetChange('next')}
                    disabled={isLastSet}
                    className={`px-2 py-1 bg-blue-500 text-white rounded ${isLastSet && 'opacity-50 cursor-not-allowed'}`}
                >
                    &gt;
                </button>
                <button
                    onClick={() => handlePageSetChange('last')}
                    disabled={isLastSet}
                    className={`px-2 py-1 bg-blue-500 text-white rounded ${isLastSet && 'opacity-50 cursor-not-allowed'}`}
                >
                    &gt;&gt;
                </button>
            </div>
        );
    };

    return (
        <div>
            <div className="h-32"></div>
            <div className="p-6 max-w-full px-56 mx-auto">
                <div className="flex justify-between items-center mb-4">
                    <form onSubmit={handleSearchSubmit} className="flex items-center space-x-4">
                        <input
                            autoComplete="off"
                            type="text"
                            name="keyword"
                            value={inputParams.keyword}
                            onChange={handleInputChange}
                            placeholder="키워드"
                            className="p-2 border border-gray-300 rounded"
                        />
                        <select
                            name="year"
                            value={inputParams.year}
                            onChange={handleInputChange}
                            className="p-2 border border-gray-300 rounded w-32"
                        >
                            <option value="">생성연도</option>
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}년
                                </option>
                            ))}
                        </select>
                        <button type="submit" className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">검색</button>
                        <label className="flex items-center space-x-2">
                            <span>개인자료만 검색</span>
                            <input
                                type="checkbox"
                                name="privateOnly"
                                checked={inputParams.privateOnly}
                                onChange={handleInputChange}
                                className="form-checkbox"
                            />
                        </label>
                    </form>
                    <Link to={'/books/new'}>
                        <button type="button" className="text-white p-2 rounded bg-green-500 hover:bg-green-600">교재 등록</button>
                    </Link>
                </div>
                <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                    <tr>
                        <th className="py-2 px-4 border-b text-base font-bold bg-blue-500 text-white">출판사</th>
                        <th className="py-2 px-4 border-b text-base font-bold bg-blue-500 text-white">교재명</th>
                        <th className="py-2 px-4 border-b text-base font-bold bg-blue-500 text-white">생성일</th>
                        <th className="py-2 px-4 border-b text-base font-bold bg-blue-500 text-white">공개여부</th>
                    </tr>
                    </thead>
                    <tbody>
                    {books.length === 0 ? (
                        <tr>
                            <td colSpan="4" className="py-4 text-center">아직 교재가 등록되지 않았습니다. 교재를 등록해주세요.</td>
                        </tr>
                    ) : (
                        books.map((book) => {
                            const createdDate = new Date(book.createdDateTime);
                            const formattedDate = `${createdDate.getFullYear()}년 ${('0' + (createdDate.getMonth() + 1)).slice(-2)}월 ${('0' + createdDate.getDate()).slice(-2)}일`;
                            const displayChapter = book.chapter ? `${book.chapter}` : '';
                            const displaySubject = book.subject ? `(${book.subject})` : '';

                            return (
                                <tr key={book.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => navigate(`/books/${book.id}`)}>
                                    <td className="py-2 px-4 border-b text-center">{book.publisher}</td>
                                    <td className="py-2 px-4 border-b text-center">{`${book.name} ${displayChapter}${displaySubject}`}</td>
                                    <td className="py-2 px-4 border-b text-center">{formattedDate}</td>
                                    <td className="py-2 px-4 border-b text-center">{book.openToPublic ? '공개자료' : '개인자료'}</td>
                                </tr>
                            );
                        })
                    )}
                    </tbody>
                </table>
                {renderPagination()}
            </div>
        </div>
    );
};

export default Books;
