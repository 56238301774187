import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const HelpId = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false); // Loading state

    const handleIdFind = async (e) => {
        e.preventDefault();

        if (!email) {
            alert('이메일을 입력해주세요.');
            return;
        }

        if (!name) {
            alert('이름을 입력해주세요.');
            return;
        }

        setLoading(true); // Disable button

        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/users/login-id/find`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, name }),
            });

            const data = await response.json();

            if (response.ok) {
                if (data && data.code === "200") {
                    if (data.data.emailSent) {
                        alert('로그인 아이디가 이메일로 전송되었습니다.');
                        navigate('/sign-in');
                    } else {
                        alert('해당하는 사용자를 찾을 수 없습니다.');
                    }
                } else if (data && data.data) {
                    alert(data.data);
                } else {
                    alert('아이디 조회에 실패했습니다. 다시 시도해주세요.');
                }
            } else {
                alert('아이디 조회에 실패했습니다. 다시 시도해주세요.');
                console.error('Failed to find login ID');
            }
        } catch (error) {
            console.error('Failed to find login ID', error);
            alert('아이디 조회 중 오류가 발생했습니다. 다시 시도해주세요.');
        } finally {
            setLoading(false); // Re-enable button
        }
    };

    return (
        <div>
            <div className="h-16 bg-gray-100"></div>
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="w-full max-w-xl p-8 space-y-8 bg-white rounded-lg shadow-lg">
                    <h2 className="text-2xl font-bold text-center">로그인 아이디 찾기</h2>
                    <form onSubmit={handleIdFind} className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">이메일</label>
                            <input
                                autoComplete="off"
                                onPaste={(e) => e.preventDefault()}  // Prevent paste
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="w-full p-2 mt-1 border border-gray-300 rounded-lg"
                                disabled={loading} // Disable input if loading
                            />
                        </div>
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">이름</label>
                            <input
                                autoComplete="off"
                                onPaste={(e) => e.preventDefault()}  // Prevent paste
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                className="w-full p-2 mt-1 border border-gray-300 rounded-lg"
                                disabled={loading} // Disable input if loading
                            />
                        </div>
                        <button
                            type="submit"
                            className={`w-full p-2 font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-700 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading} // Disable button if loading
                        >
                            {loading ? '아이디 찾는 중...' : '아이디 찾기'}
                        </button>
                    </form>
                    <div className="space-y-4">
                        <p className="text-red-500 text-left">
                            *로그인 아이디는 이메일로 전송됩니다.
                        </p>
                        <p className="text-red-500 text-left">
                            *학생 계정은 찾을 수 없습니다.
                            <br />
                            &nbsp;다니는 학원의 선생님에게 문의해주세요.
                        </p>
                    </div>
                    <div className="space-y-4">
                        <p className="text-center">
                            다시 로그인하러 가시겠습니까?
                            <br />
                            <Link to="/sign-in" className="text-blue-500 hover:underline">로그인 페이지로 돌아가기</Link>
                        </p>
                        <p className="text-center">
                            비밀번호를 잊으셨나요?
                            <br />
                            <Link to="/help/pw" className="text-blue-500 hover:underline">비밀번호 찾기</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HelpId;
