import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const TeacherVerify = () => {
    const navigate = useNavigate();
    const { teacherId } = useParams(); // URL에서 teacherId를 추출합니다.

    useEffect(() => {
        // teacherId가 없을 경우, 잘못된 요청으로 간주하고 홈으로 리다이렉트합니다.
        if (!teacherId) {
            alert('유효하지 않은 요청입니다.');
            navigate('/');
            return;
        }

        // 이메일 인증 API 호출
        const verifyTeacher = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/teachers/${teacherId}/verify`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();

                if (data.data.verified) {
                    alert('인증되었습니다.');
                    navigate('/sign-in'); // 인증 성공 시 로그인 화면으로 이동
                } else {
                    alert('인증이 불가능합니다.');
                    navigate('/'); // 인증 실패 시 메인 화면으로 이동
                }
            } catch (error) {
                console.error('Error:', error);
                alert('오류가 발생했습니다. 다시 시도하십시오.');
                navigate('/');
            }
        };
        verifyTeacher();
    }, [teacherId, navigate]); // teacherId와 navigate가 변경될 때마다 useEffect 실행

    return (
        <div style={{ textAlign: 'center', marginTop: '3rem' }}>
            <h1>이메일 인증 중...</h1>
        </div>
    );
};

export default TeacherVerify;
