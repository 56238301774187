import { useEffect } from 'react';

export default function GlobalEventHandler() {

    const validSeconds = 10;
    const interval = 1000;

    function transferLocalStorageIntoData() {
        if (!localStorage.getItem("data")) {
            let expiration = new Date();
            expiration.setSeconds(expiration.getSeconds() + validSeconds);

            const data = {
                expiration: expiration.getTime(),
            };

            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                data[key] = localStorage.getItem(key);
            }
            localStorage.clear();
            localStorage.setItem("data", JSON.stringify(data));
        }
    }

    function setLocalStorageFromData() {
        if (localStorage.getItem("data")) {
            const data = JSON.parse(localStorage.getItem("data"));
            const now = new Date().getTime();
            const expiration = Number(data.expiration);

            if (data) {
                if (now <= expiration) {
                    for (const key in data) {
                        localStorage.setItem(key, data[key]);
                    }
                    localStorage.removeItem("data");
                    localStorage.removeItem("expiration");
                } else {
                    localStorage.clear();
                }
            }
        }
    }

    function handleInterval() {
        if (localStorage.getItem("data")) {
            setLocalStorageFromData();
        }
    }

    useEffect(() => {
        // 페이지를 닫을 때 로컬스토리지 데이터에 유효시간을 지정
        window.addEventListener("beforeunload", transferLocalStorageIntoData);

        // 만약 현재시간이 로컬스토리지 데이터의 유효시간 이내라면 데이터를 복원
        window.addEventListener("load", setLocalStorageFromData);

        // 다른 탭에서 unload 이벤트를 발생시키는 경우에 대비하여 주기적으로 로컬스토리지 데이터를 확인
        const intervalId = setInterval(handleInterval, interval);

        return () => {
            window.removeEventListener("beforeunload", transferLocalStorageIntoData);
            window.removeEventListener("load", setLocalStorageFromData);
            clearInterval(intervalId);
        };
    }, []);

    return null;
}
