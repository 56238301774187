import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const EndTrial = () => {
    const navigate = useNavigate();
    const [loginId, setLoginId] = useState('');
    const [loginPw, setLoginPw] = useState('');
    const [loading, setLoading] = useState(false); // Loading state

    const handleEndTrial = async (e) => {
        e.preventDefault();

        if (!loginId) {
            alert('아이디를 입력해주세요.');
            return;
        }

        if (!loginPw) {
            alert('비밀번호를 입력해주세요.');
            return;
        }

        setLoading(true); // Disable button

        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/academies/end-trial`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ loginId, loginPw }),
            });

            const data = await response.json();

            if (response.ok) {
                if (data && data.code === "200" && data.data.endTrial) {
                    alert('정규회원으로 전환되었습니다.');
                    navigate('/sign-in');
                } else if (data && data.data) {
                    alert(data.data);
                } else {
                    alert('정규회원 전환에 실패했습니다.');
                }
            } else {
                alert('정규회원 전환에 실패했습니다. 관리자에게 문의해주세요.');
                console.error('Failed to convert to full member');
            }
        } catch (error) {
            console.error('Failed to convert to full member', error);
            alert('정규회원 전환 중 오류가 발생했습니다. 다시 시도해주세요.');
        } finally {
            setLoading(false); // Re-enable button
        }
    };

    return (
        <div>
            <div className="h-16 bg-gray-100"></div>
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="w-full max-w-xl p-8 space-y-8 bg-white rounded-lg shadow-lg">
                    <h2 className="text-2xl font-bold text-center">정규회원으로 전환하기</h2>
                    <form onSubmit={handleEndTrial} className="space-y-6">
                        <div>
                            <label htmlFor="loginId" className="block text-sm font-medium text-gray-700">아이디</label>
                            <input
                                autoComplete="off"
                                onPaste={(e) => e.preventDefault()}  // Prevent paste
                                type="text"
                                id="loginId"
                                value={loginId}
                                onChange={(e) => setLoginId(e.target.value)}
                                required
                                className="w-full p-2 mt-1 border border-gray-300 rounded-lg"
                                disabled={loading} // Disable input if loading
                            />
                        </div>
                        <div>
                            <label htmlFor="loginPw" className="block text-sm font-medium text-gray-700">비밀번호</label>
                            <input
                                autoComplete="off"
                                onPaste={(e) => e.preventDefault()}  // Prevent paste
                                type="password"
                                id="loginPw"
                                value={loginPw}
                                onChange={(e) => setLoginPw(e.target.value)}
                                required
                                className="w-full p-2 mt-1 border border-gray-300 rounded-lg"
                                disabled={loading} // Disable input if loading
                            />
                        </div>
                        <button
                            type="submit"
                            className={`w-full p-2 font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-700 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading} // Disable button if loading
                        >
                            {loading ? '전환 중...' : '정규회원 전환'}
                        </button>
                    </form>
                    <div className="space-y-4">
                        <p className="text-red-500 text-left">
                            *정규회원 전환은 원장님만 가능합니다.
                        </p>
                    </div>
                    <div className="space-y-4">
                        <p className="text-center">
                            다시 로그인하러 가시겠습니까?
                            <br />
                            <Link to="/sign-in" className="text-blue-500 hover:underline">로그인 페이지로 돌아가기</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EndTrial;
