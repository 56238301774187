import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import UseFetchToken from "../hooks/UseFetchToken";
import { StudyStatus, StudyTarget, StudyType } from "../constants/Constants";
import { toast, ToastContainer } from "react-toastify";

const StudyResult = () => {
    const isTeacher = JSON.parse(localStorage.getItem("roles")).includes("ROLE_TEACHER");
    const [count, setCount] = useState(0);
    const [editingWordId, setEditingWordId] = useState(null); // 수정 중인 단어 ID 관리
    const [correct, setCorrect] = useState(null); // 정오 상태
    const [reason, setReason] = useState(null); // 수정 이유

    const { studyId } = useParams();
    const navigate = useNavigate();
    const [study, setStudy] = useState(null);
    const [studyWords, setStudyWords] = useState([]);
    const [student, setStudent] = useState(null);
    const [teacher, setTeacher] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (Boolean(studyId)) {
            const fetchStudy = async () => {
                const data = await UseFetchToken(`/api/studies/${studyId}`, {
                    method: 'GET',
                });

                if (data) {
                    setStudy(data.study);
                    setStudyWords(data.studyWords);
                    setStudent(data.student);
                    setTeacher(data.teacher);
                }
                setLoading(false);
            };
            fetchStudy();
        } else {
            setLoading(false);
            alert("학습 정보를 찾을 수 없습니다.");
            navigate(-1);
        }
    }, [studyId, count]);

    if (loading) {
        return <div className="flex items-center justify-center h-screen">Loading...</div>;
    }

    const renderSchoolGradeLables = (schoolGrade) => {
        const grades = [
            { label: '1학년', value: 1 },
            { label: '2학년', value: 2 },
            { label: '3학년', value: 3 },
            { label: '4학년', value: 4 },
            { label: '5학년', value: 5 },
            { label: '6학년', value: 6 },
            { label: '1학년', value: 7 },
            { label: '2학년', value: 8 },
            { label: '3학년', value: 9 },
            { label: '1학년', value: 10 },
            { label: '2학년', value: 11 },
            { label: '3학년', value: 12 },
        ];
        return grades.filter((grade) => grade.value === schoolGrade).map((grade) => (grade.label));
    };

    const renderSubmitDateTime = (submitDateTime) => {
        if (!submitDateTime) {
            return '제출 전';
        }
        const date = new Date(submitDateTime);
        const year = date.getFullYear().toString().slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hour = ('0' + date.getHours()).slice(-2);
        const minute = ('0' + date.getMinutes()).slice(-2);
        const ampm = date.getHours() < 12 ? '오전' : '오후';
        return `${year}년 ${month}월 ${day}일 ${ampm} ${hour}시 ${minute}분`;
    };

    const handleEditClick = (wordId) => {
        if (editingWordId === wordId) {
            setEditingWordId(null);
            setCorrect(null);
            setReason(null);
        } else {
            setEditingWordId(wordId);
            setCorrect(null);
            setReason(null);
        }
    };

    const handleSaveClick = async (wordId) => {
        if (correct === null) {
            alert("정오 여부를 입력해주세요.");
            return;
        }

        try {
            const data = await UseFetchToken(`/api/studies/${studyId}/study-words/${wordId}/correct`, {
                method: 'PATCH',
                body: JSON.stringify({
                    correct: correct,
                    reason: reason
                })
            });

            if (data && data.studyWordId) {
                setCount(count + 1); // 페이지 새로고침 효과
                setEditingWordId(null); // 수정 모드 종료
                toast.success("수정되었습니다!", {
                    position: 'top-center',
                    autoClose: 500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                alert("수정에 실패했습니다.");
            }
        } catch (error) {
            console.error("Error:", error);
            alert("수정 중 오류가 발생했습니다.");
        }
    };

    const handleDeleteClick = async () => {
        if (window.confirm("정말로 이 학습 기록을 삭제하시겠습니까?")) {
            try {
                const data = await UseFetchToken(`/api/studies/${studyId}`, {
                    method: 'DELETE'
                });

                if (data && data.studyId) {
                    toast.success("삭제되었습니다.", {
                        position: 'top-center',
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(() => {
                        navigate('/studies');
                    }, 1000);
                } else {
                    alert("삭제에 실패했습니다.");
                }
            } catch (error) {
                console.error("Error:", error);
                alert("삭제 중 오류가 발생했습니다.");
            }
        }
    };

    return (
        <div className="p-6">
            <ToastContainer />
            <div className="h-32"></div>
            <h1 className="text-2xl font-bold mb-4">학습 상세 조회</h1>

            {study && (
                <div className="mb-6">
                    <h2 className="text-xl font-semibold">{study.title}</h2>
                    <p>학습 상태: {StudyStatus[study.status].value}</p>
                    <p>학습 유형: {StudyType[study.type].value}</p>
                    <p>학습 대상: {StudyTarget[study.target].value}</p>
                    <p>제출일: {renderSubmitDateTime(study.submitDateTime)}</p>
                </div>
            )}

            {student && (
                <div className="mb-6">
                    <h2 className="text-xl font-semibold">학생 정보</h2>
                    <p>이름: {student.name}</p>
                    <p>전화번호: {student.phone}</p>
                    <p>학교: {student.schoolName}, {renderSchoolGradeLables(student.schoolGrade)}</p>
                </div>
            )}

            {teacher && (
                <div className="mb-6">
                    <h2 className="text-xl font-semibold">마지막으로 확인한 선생님({teacher.name})</h2>
                </div>
            )}

            <h2 className="text-xl font-semibold mb-4">학습 단어</h2>
            <table className="min-w-full bg-white">
                <thead>
                <tr>
                    <th className="py-2 px-4 border-b text-center">질문</th>
                    <th className="py-2 px-4 border-b text-center">제출된 답변</th>
                    <th className="py-2 px-4 border-b text-center">정답</th>
                    <th className="py-2 px-4 border-b text-center">정답 여부</th>
                    <th className="py-2 px-4 border-b text-center">정오 채점 이유</th>
                    {isTeacher && <th className="py-2 px-4 border-b text-center"></th>}
                </tr>
                </thead>
                <tbody>
                {studyWords.map((word) => (
                    <React.Fragment key={word.id}>
                        <tr>
                            <td className="py-2 px-4 border-b text-center align-middle">{word.question}</td>
                            <td className="py-2 px-4 border-b text-center align-middle">{word.submit}</td>
                            <td className="py-2 px-4 border-b text-center align-middle">{word.answer}</td>
                            <td className="py-2 px-4 border-b text-center align-middle">{word.correct ? "O" : "X"}</td>
                            <td className="py-2 px-4 border-b text-center align-middle">{word.reason || "-"}</td>
                            {isTeacher && (
                                <td className="py-2 px-4 border-b text-center align-middle">
                                    <button
                                        className={`px-4 py-2 rounded ${
                                            editingWordId === word.id ? "bg-red-500 text-white" : "bg-green-500 text-white"
                                        }`}
                                        onClick={() => handleEditClick(word.id)}
                                    >
                                        {editingWordId === word.id ? "수정취소" : "수정하기"}
                                    </button>
                                </td>
                            )}
                        </tr>
                        {editingWordId === word.id && (
                            <tr>
                                <td colSpan="1" className="py-2 px-4 border-b text-center align-middle"></td>
                                <td colSpan="3" className="py-2 px-4 border-b text-center align-middle">
                                    <div className="flex justify-center">
                                        <label className="mr-4">
                                            <input
                                                type="radio"
                                                value="true"
                                                checked={word.correct === true}
                                                onChange={() => setCorrect(true)}
                                            />
                                            정답
                                        </label>
                                        <label className="mr-4">
                                            <input
                                                type="radio"
                                                value="false"
                                                checked={word.correct === false}
                                                onChange={() => setCorrect(false)}
                                            />
                                            오답
                                        </label>
                                    </div>
                                </td>
                                <td colSpan="1" className="py-2 px-4 border-b text-center align-middle">
                                    <input
                                        type="text"
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                        placeholder="수정 이유를 입력하세요"
                                        className="ml-4 p-2 border rounded text-center w-full"
                                    />
                                </td>
                                <td colSpan="1" className="py-2 px-4 border-b text-center align-middle">
                                    <button
                                        className="px-4 py-2 bg-blue-500 text-white rounded"
                                        onClick={() => handleSaveClick(word.id)}
                                    >
                                        수정완료
                                    </button>
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
                </tbody>
            </table>
            {isTeacher && (
                <div className="flex justify-center mt-9">
                    <button
                        className="px-6 py-3 bg-red-500 text-white font-semibold rounded-lg"
                        onClick={handleDeleteClick}
                    >
                        성적 삭제
                    </button>
                </div>
            )}
            <div className="h-32"></div>
        </div>
    );
};

export default StudyResult;
