import React, { useState, useEffect } from 'react';
import UseFetchToken from "../hooks/UseFetchToken";
import { RoleName, UserStatus } from "../constants/Constants";
import PasswordChange from "./PasswordChange";

const MyPageStudent = () => {

    const [studentData, setStudentData] = useState({
        loginId: '',
        email: '',
        name: '',
        phone: '',
        street: '',
        addressDetail: '',
        postalCode: '',
        status: '',
        roles: [],
        createdDateTime: '',
        attendanceId: '',
        schoolName: '',
        schoolGrade: '',
        parentName: '',
        parentPhone: ''
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch the student data
        const fetchStudent = async () => {
            setLoading(true);
            try {
                const data = await UseFetchToken('/api/users/me', {
                    method: 'GET',
                });
                setStudentData(data);
            } catch (error) {
                console.error('Error fetching student data:', error);
            }
            setLoading(false);
        };
        fetchStudent();
    }, []);

    if (loading) {
        return <div className="flex items-center justify-center h-screen">Loading...</div>;
    }

    const getStatusText = (status) => {
        const statusEntry = UserStatus[status];
        return statusEntry ? statusEntry.value : '알 수 없음';
    };

    const getRoleText = (roles) => {
        if (roles.includes('ROLE_DIRECTOR')) {
            return RoleName.ROLE_DIRECTOR.value;
        } else if (roles.includes('ROLE_TEACHER')) {
            return RoleName.ROLE_TEACHER.value;
        } else if (roles.includes('ROLE_STUDENT')) {
            return RoleName.ROLE_STUDENT.value;
        } else {
            return '';
        }
    };

    return (
        <div>
            <form className="space-y-4 p-6">
                <div className="h-32"></div>
                <h2 className="text-2xl font-bold">정보 수정</h2>
                <div>
                    <label className="block mb-2">아이디</label>
                    <p className="block w-full p-2 border border-gray-300 rounded">
                        {studentData.loginId}
                    </p>
                </div>
                <div>
                    <label className="block mb-2">출석 아이디</label>
                    <p className="block w-full p-2 border border-gray-300 rounded">
                        {studentData.attendanceId}
                    </p>
                </div>
                <div>
                    <label className="block mb-2">이름</label>
                    <p className="block w-full p-2 border border-gray-300 rounded">
                        {studentData.name}
                    </p>
                </div>
                <div>
                    <label className="block mb-2">연락처</label>
                    <p className="block w-full p-2 border border-gray-300 rounded">
                        {studentData.phone}
                    </p>
                </div>
                <div>
                    <label className="block mb-2">주소</label>
                    <p className="block w-full p-2 border border-gray-300 rounded">
                        {studentData.street}
                    </p>
                </div>
                <div>
                    <label className="block mb-2">상세주소</label>
                    <p className="block w-full p-2 border border-gray-300 rounded">
                        {studentData.addressDetail}
                    </p>
                </div>
                <div>
                    <label className="block mb-2">우편번호</label>
                    <p className="block w-full p-2 border border-gray-300 rounded">
                        {studentData.postalCode}
                    </p>
                </div>
                <div>
                    <label className="block mb-2">학교</label>
                    <p className="block w-full p-2 border border-gray-300 rounded">
                        {studentData.schoolName}
                        {studentData.schoolGrade ? `(${studentData.schoolGrade}학년)` : ''}
                    </p>
                </div>
                <div>
                    <label className="block mb-2">보호자</label>
                    <p className="block w-full p-2 border border-gray-300 rounded">
                        {studentData.parentName}
                        {studentData.parentPhone ? `(${studentData.parentPhone})` : ''}
                    </p>
                </div>
                <div>
                    <label className="block mb-2">회원 상태</label>
                    <p className="block w-full p-2 border border-gray-300 rounded">
                        {getStatusText(studentData.status)}
                    </p>
                </div>
                <div>
                    <label className="block mb-2">권한</label>
                    <p className="block w-full p-2 border border-gray-300 rounded">
                        {getRoleText(studentData.roles)}
                    </p>
                </div>
                <div>
                    <label className="block mb-2">가입일</label>
                    <p className="block w-full p-2 border border-gray-300 rounded">
                        {(() => {
                            const date = new Date(studentData.createdDateTime);
                            const year = date.getFullYear();
                            const month = ('0' + (date.getMonth() + 1)).slice(-2);
                            const day = ('0' + date.getDate()).slice(-2);
                            return `${year}년 ${month}월 ${day}일`;
                        })()}
                    </p>
                </div>
            </form>
            <PasswordChange />
            <div className="h-32"></div>
        </div>
    );
};

export default MyPageStudent;
