import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import UseFetchToken from '../hooks/UseFetchToken';
import { AcademyStatus } from "../constants/Constants";

const Academy = () => {
    const [academy, setAcademy] = useState(null);
    const { academyId } = useParams();
    const [formData, setFormData] = useState({
        name: '',
        street: '',
        addressDetail: '',
        postalCode: '',
        phone: '',
        email: '',
        status: '',
        openToPublic: false,
        createdDateTime: ''
    });

    const roles = JSON.parse(localStorage.getItem('roles'));
    const isDirector = roles && roles.includes('ROLE_DIRECTOR');
    const isTrial = localStorage.getItem('status') === 'TRIAL';

    const [checkTimeouts, setCheckTimeouts] = useState({
        name: null,
        phone: null,
    });
    const [isNameChecked, setIsNameChecked] = useState(false);
    const [nameCheckMessage, setNameCheckMessage] = useState('');
    const [isCheckingName, setIsCheckingName] = useState(false);

    const [isPhoneChecked, setIsPhoneChecked] = useState(false);
    const [phoneCheckMessage, setPhoneCheckMessage] = useState('');
    const [isCheckingPhone, setIsCheckingPhone] = useState(false);

    const [isPostalCodeValid, setIsPostalCodeValid] = useState(true);
    const [postalCodeMessage, setPostalCodeMessage] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);
    const [count, setCount] = useState(0);

    useEffect(() => {
        const fetchAcademyDetails = async () => {
            const data = await UseFetchToken(`/api/academies/${academyId}`, {
                method: 'GET',
            });
            if (data) {
                setAcademy(data.academy);

                let academyStatusValue = data.academy.status;
                for (const s of AcademyStatus.values()) {
                    if (s.name === data.academy.status) {
                        academyStatusValue = s.value;
                        break;
                    }
                }

                setFormData({
                    name: data.academy.name,
                    street: data.academy.street,
                    addressDetail: data.academy.addressDetail,
                    postalCode: data.academy.postalCode,
                    phone: data.academy.phone,
                    email: data.academy.email,
                    status: academyStatusValue,
                    openToPublic: data.academy.openToPublic,
                    createdDateTime: data.academy.createdDateTime
                });
            }
        };
        fetchAcademyDetails();

        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                setShowTooltip(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [academyId, count]);

    const handleInputChange = (e, type) => {
        const { name, value, type: inputType, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: inputType === 'checkbox' ? checked : value,
        }));

        if (name === 'name') {
            handleDelayedCheck('name', value, setIsNameChecked, setNameCheckMessage, setIsCheckingName, checkNameDuplicate, validateAcademyName);
        } else if (name === 'phone') {
            handleDelayedCheck('phone', value, setIsPhoneChecked, setPhoneCheckMessage, setIsCheckingPhone, checkPhoneDuplicate, validateAcademyPhone);
        } else if (name === 'postalCode') {
            const { isValid, message } = validateAcademyPostalCode(value);
            setIsPostalCodeValid(isValid);
            setPostalCodeMessage(message);
        }
    };

    const handleDelayedCheck = (field, value, setIsChecked, setCheckMessage, setIsChecking, checkFunction, validateFunction) => {
        setIsChecked(false);
        setCheckMessage('');
        setIsChecking(true);
        if (checkTimeouts[field]) {
            clearTimeout(checkTimeouts[field]);
        }
        if (validateFunction) {
            const validationResult = validateFunction(value);
            if (!validationResult.isValid) {
                setCheckMessage(validationResult.message);
                setIsChecked(false);
                setIsChecking(false);
                return;
            }
        }
        const timeout = setTimeout(() => checkFunction(value), 1000);
        setCheckTimeouts({
            ...checkTimeouts,
            [field]: timeout,
        });
    };

    const validateAcademyName = (name) => {
        if (!name) {
            return { isValid: false, message: '학원 이름을 입력해주세요.' };
        }
        if (!/^[가-힣]+$/.test(name)) {
            return { isValid: false, message: '학원 이름은 한글만 가능합니다.' };
        }
        if (name.length < 2 || name.length > 30) {
            return { isValid: false, message: '학원 이름은 2자에서 30자 사이여야 합니다.' };
        }
        return { isValid: true, message: '' };
    };

    const validateAcademyPostalCode = (postalCode) => {
        if (!postalCode) {
            return { isValid: true, message: '' }; // Postal code is optional
        }
        if (!/^\d{5}$/.test(postalCode)) {
            return { isValid: false, message: '우편번호는 숫자로 5자리여야 합니다.' };
        }
        return { isValid: true, message: '' };
    };

    const validateAcademyPhone = (phone) => {
        if (!phone) {
            return { isValid: true, message: '' }; // Phone is optional
        }
        if (!/^[0-9]{8,12}$/.test(phone)) {
            return { isValid: false, message: '학원 전화번호는 8자리 이상, 12자리 이하의 숫자로 입력해주세요.' };
        }
        return { isValid: true, message: '' };
    };

    const checkNameDuplicate = async (name) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/academies/check-dupl?name=${encodeURIComponent(name)}&academyId=${academyId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const result = await response.json();

            if (result.code === "200") {
                if (result.data.isOk) {
                    setNameCheckMessage('사용 가능한 학원 이름입니다.');
                    setIsNameChecked(true);
                } else {
                    setNameCheckMessage('이미 사용 중인 학원 이름입니다.');
                    setIsNameChecked(false);
                }
            } else if (result.code.charAt(0) === "0") {
                setNameCheckMessage(result.data);
                setIsNameChecked(false);
            } else {
                console.error(result);
                console.error(result.reasons);
            }
        } catch (error) {
            console.error('Error:', error);
            setNameCheckMessage('이름 중복 검사 중 에러 발생.');
            setIsNameChecked(false);
        } finally {
            setIsCheckingName(false);
        }
    };

    const checkPhoneDuplicate = async (phone) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/academies/check-dupl?phone=${encodeURIComponent(phone)}&academyId=${academyId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const result = await response.json();

            if (result.code === "200" && result.data.isOk) {
                setPhoneCheckMessage('사용 가능한 연락처입니다.');
                setIsPhoneChecked(true);
            } else {
                setPhoneCheckMessage('이미 사용 중인 연락처입니다.');
                setIsPhoneChecked(false);
            }
        } catch (error) {
            console.error('Error:', error);
            setPhoneCheckMessage('연락처 중복 검사 중 에러 발생.');
            setIsPhoneChecked(false);
        } finally {
            setIsCheckingPhone(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateAcademyName(formData.name).isValid) {
            alert('학원 이름 중복 검사를 완료해주세요.');
            return;
        }

        if (!validateAcademyPhone(formData.phone).isValid) {
            alert('학원 전화번호를 확인해주세요.');
            return;
        }

        if (!validateAcademyPostalCode(formData.postalCode).isValid) {
            alert('학원 우편번호를 확인해주세요.');
            return;
        }

        const { status, createdDateTime, ...updateData } = formData;
        const response = await UseFetchToken(`/api/academies/${academyId}`, {
            method: 'PATCH',
            body: JSON.stringify({
                name: updateData.name,
                phone: updateData.phone,
                street: updateData.street,
                addressDetail: updateData.addressDetail,
                postalCode: updateData.postalCode,
                openToPublic: updateData.openToPublic,
            }),
        });
        if (response) {
            alert('학원 정보가 성공적으로 수정되었습니다.');
            setCount(count + 1);
        }
    };

    const handleWithdraw = async () => {
        if (window.confirm('탈퇴를 신청하면 2주 뒤에 모든 데이터가 모두 삭제됩니다.\n학원 탈퇴를 신청하시겠습니까?') === false) {
            return;
        }

        try {
            const response = await UseFetchToken(`/api/academies/${academyId}`, {
                method: 'DELETE',
            });
            if (response && response.academyId) {
                alert('학원 탈퇴가 성공적으로 처리되었습니다. 2주 뒤에 모든 데이터가 삭제됩니다');
                setCount(count + 1);
            } else {
                alert('학원 탈퇴 처리 중 오류가 발생했습니다.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('학원 탈퇴 처리 중 오류가 발생했습니다.');
        }
    };

    const handleCancelWithdraw = async () => {
        try {
            const response = await UseFetchToken(`/api/academies/${academyId}/revoke`, {
                method: 'PATCH',
            });
            if (response && response.academyId) {
                alert('학원 탈퇴 취소가 성공적으로 처리되었습니다.');
                setCount(count + 1);
            } else {
                alert('학원 탈퇴 취소 처리 중 오류가 발생했습니다.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('학원 탈퇴 취소 처리 중 오류가 발생했습니다.');
        }
    };

    const handleTrialEnd = async (e) => {
        e.preventDefault();
        const loginId = e.target.loginId.value;
        const loginPw = e.target.loginPw.value;

        try {
            const data = await UseFetchToken('/api/academies/end-trial', {
                method: 'POST',
                body: JSON.stringify({ loginId, loginPw }),
            });

            if (data && data.endTrial) {
                localStorage.setItem('status', 'ACTIVE');
                alert('정규회원으로 전환되었습니다.');
                setCount(count + 1);
            } else {
                alert('정규회원 전환 중 오류가 발생했습니다.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('정규회원 전환 중 오류가 발생했습니다.');
        }
    };

    if (!academy) {
        return <div className="flex items-center justify-center h-screen">Loading...</div>;
    }

    return (
        <div className="mx-auto bg-white px-12">
            <div className="h-32"></div>
            <div className="mb-8 p-6 rounded-lg">
                <h1 className="text-5xl font-extrabold mb-8 text-center">학원 정보</h1>
                <form className="space-y-4 mr-10" onSubmit={handleSubmit}>
                    <div>
                        <div className="flex items-start space-x-4">
                            <label className="w-1/6 text-lg text-gray-700 text-center pt-1" htmlFor="name">학원명</label>
                            <div className="w-5/6">
                                <input
                                    autoComplete="off"
                                    onPaste={(e) => e.preventDefault()}  // Prevent paste
                                    className="block w-full p-2 border border-gray-300 rounded-lg"
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                />
                                {isCheckingName ? (
                                    <p className="mt-1 text-blue-500">중복 검사 중...</p>
                                ) : (
                                    <p className={`mt-1 ${isNameChecked ? 'text-green-500' : 'text-red-500'}`}>
                                        {nameCheckMessage}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-start space-x-4">
                        <label className="w-1/6 text-lg text-gray-700 text-center pt-1" htmlFor="phone">전화번호</label>
                        <div className="w-5/6">
                            <input
                                autoComplete="off"
                                onPaste={(e) => e.preventDefault()}  // Prevent paste
                                className="block w-full p-2 border border-gray-300 rounded-lg"
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                            />
                            {isCheckingPhone ? (
                                <p className="mt-1 text-blue-500">중복 검사 중...</p>
                            ) : (
                                <p className={`mt-1 ${isPhoneChecked ? 'text-green-500' : 'text-red-500'}`}>
                                    {phoneCheckMessage}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center space-x-4">
                        <label className="w-1/6 text-lg text-gray-700 text-center" htmlFor="email">이메일</label>
                        <input
                            autoComplete="off"
                            onPaste={(e) => e.preventDefault()}  // Prevent paste
                            className="w-5/6 p-2 border border-gray-300 rounded-lg bg-gray-100 cursor-default"
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            onClick={() => alert('이메일은 수정할 수 없습니다.')}
                            readOnly
                        />
                    </div>
                    <div className="flex items-center space-x-4">
                        <label className="w-1/6 text-lg text-gray-700 text-center" htmlFor="street">주소</label>
                        <input
                            autoComplete="off"
                            onPaste={(e) => e.preventDefault()}  // Prevent paste
                            className="w-5/6 p-2 border border-gray-300 rounded-lg"
                            type="text"
                            name="street"
                            value={formData.street}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex items-center space-x-4">
                        <label className="w-1/6 text-lg text-gray-700 text-center" htmlFor="addressDetail">상세주소</label>
                        <input
                            autoComplete="off"
                            onPaste={(e) => e.preventDefault()}  // Prevent paste
                            className="w-5/6 p-2 border border-gray-300 rounded-lg"
                            type="text"
                            name="addressDetail"
                            value={formData.addressDetail}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex items-start space-x-4">
                        <label className="w-1/6 text-lg text-gray-700 text-center pt-1"
                               htmlFor="postalCode">우편번호</label>
                        <div className="w-5/6">
                            <input
                                autoComplete="off"
                                onPaste={(e) => e.preventDefault()}  // Prevent paste
                                className="block w-full p-2 border border-gray-300 rounded-lg"
                                type="text"
                                name="postalCode"
                                maxLength="5"
                                value={formData.postalCode}
                                onChange={handleInputChange}
                            />
                            {isPostalCodeValid ? null : (
                                <p className="mt-1 text-red-500">{postalCodeMessage}</p>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center space-x-4">
                        <label className="w-1/6 text-lg text-gray-700 text-center" htmlFor="openToPublic">공개여부</label>
                        <span className="w-5/6 p-4">
                            학원을 공개하면 일반인이 학원을 검색할 수 있으며 주소, 연락처, 이메일이 노출됩니다.
                            <input
                                className="ml-4"
                                type="checkbox"
                                name="openToPublic"
                                checked={formData.openToPublic}
                                onChange={handleInputChange}
                            />
                        </span>
                    </div>
                    <div className="text-center">
                        <button
                            type="submit"
                            className="bg-blue-500 text-white py-2 px-4 rounded-lg"
                        >
                            수정하기
                        </button>
                    </div>
                </form>
                <div className="space-y-4 mt-10">
                    <div className="flex items-center space-x-4">
                        <label className="w-1/6 text-lg text-gray-700 text-center">학원상태</label>
                        <span className="w-4/6 p-2">{formData.status}</span>
                        {isDirector && (formData.status === AcademyStatus.WITHDRAWN.value ? (
                            <button
                                onClick={handleCancelWithdraw}
                                className="bg-green-500 text-white py-2 px-4 rounded-lg"
                            >
                                탈퇴취소
                            </button>
                        ) : (
                            <button
                                onClick={handleWithdraw}
                                className="bg-red-500 text-white py-2 px-4 rounded-lg"
                            >
                                탈퇴신청
                            </button>
                        ))}
                    </div>
                    <div className="flex items-center space-x-4">
                        <label className="w-1/6 text-lg text-gray-700 text-center">가입일</label>
                        <span className="w-5/6 p-2">
                            {(() => {
                                const date = new Date(formData.createdDateTime);
                                const year = date.getFullYear();
                                const month = ('0' + (date.getMonth() + 1)).slice(-2); // 2자리로 맞추기 위해 '0'을 붙이고 slice 사용
                                const day = ('0' + date.getDate()).slice(-2); // 2자리로 맞추기 위해 '0'을 붙이고 slice 사용
                                return `${year}년 ${month}월 ${day}일`;
                            })()}
                        </span>
                    </div>
                    {isTrial && (
                        <form onSubmit={handleTrialEnd}>
                            <div className="flex items-center space-x-4">
                                <label className="relative w-1/6 text-lg text-gray-700 text-center">
                                    {showTooltip && (
                                        <div
                                            className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-80 bg-white border border-gray-300 p-4 rounded-lg shadow-lg"
                                            ref={tooltipRef}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <p>아이디와 비밀번호를 입력하고 <br/> 정식서비스로 전환하세요.</p>
                                        </div>
                                    )}
                                    체험판 이용중&nbsp;
                                    <span
                                        className="cursor-pointer"
                                        onClick={() => setShowTooltip(!showTooltip)}
                                    >
                                        (?)
                                    </span>
                                </label>
                                <input
                                    autoComplete="off"
                                    onPaste={(e) => e.preventDefault()}  // Prevent paste
                                    className="block w-1/6 p-2 border border-gray-300 rounded-lg"
                                    type="text"
                                    name="loginId"
                                    placeholder="아이디"
                                    required
                                />
                                <input
                                    className="block w-1/6 p-2 border border-gray-300 rounded-lg"
                                    type="password"
                                    name="loginPw"
                                    placeholder="비밀번호"
                                    required
                                />
                                <button
                                    className="bg-blue-500 text-white py-2 px-4 rounded-lg"
                                    type="submit"
                                >
                                    정식전환
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
            <div className="h-32"></div>
        </div>
    );
};

export default Academy;
