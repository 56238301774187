import React, {createContext, useEffect, useState} from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

    const [user, setUser] = useState(() => {
        setLocalStorageFromData();

        const accessToken = localStorage.getItem('accessToken');
        const roles = JSON.parse(localStorage.getItem('roles')) || [];
        const userId = localStorage.getItem('userId');
        const academyId = localStorage.getItem('academyId');

        return accessToken && userId && academyId
            ? { isLoggedIn: true, roles, userId, academyId }
            : { isLoggedIn: false, roles: [], userId: null, academyId: null };
    });

    function setLocalStorageFromData() {
        if (localStorage.getItem("data")) {
            const data = JSON.parse(localStorage.getItem("data"));
            const now = new Date().getTime();
            const expiration = Number(data.expiration);

            if (data) {
                if (now <= expiration) {
                    for (const key in data) {
                        localStorage.setItem(key, data[key]);
                    }
                    localStorage.removeItem("data");
                    localStorage.removeItem("expiration");
                } else {
                    localStorage.clear();
                }
            }
        }
    }

    const login = (roles, userId, academyId) => {
        localStorage.setItem('accessToken', 'some_token'); // 실제 토큰으로 대체
        localStorage.setItem('roles', JSON.stringify(roles));
        localStorage.setItem('userId', userId);
        localStorage.setItem('academyId', academyId);

        setUser({
            isLoggedIn: true,
            roles,
            userId,
            academyId,
        });
    };

    const logout = () => {
        localStorage.clear();
        setUser({
            isLoggedIn: false,
            roles: [],
            userId: null,
            academyId: null,
        });
        alert('로그아웃되었습니다.');
    };

    return (
        <UserContext.Provider value={{ user, login, logout }}>
            {children}
        </UserContext.Provider>
    );
};
