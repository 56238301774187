import React, { useState, useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom";
import {StudyStatus, StudyType, StudyClassification, StudyTarget} from "../constants/Constants";
import StudyWordTracing from "../fragments/StudyWordTracing";
import StudyWordWriting from "../fragments/StudyWordWriting";
import StudyWordSelect from "../fragments/StudyWordSelect";
import UseFetchToken from "../hooks/UseFetchToken";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const StudyStart = () => {

    const MAX_VISIBLE_BUTTONS = 9;
    const { studyId } = useParams();
    const navigate = useNavigate();
    const [study, setStudy] = useState(null);
    const [studyWords, setStudyWords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [submissions, setSubmissions] = useState([]);
    const [submitLastAnswer, setSubmitLastAnswer] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (Boolean(studyId)) {
            const fetchStudy = async () => {
                const data = await UseFetchToken(`/api/studies/${studyId}`, {
                    method: 'GET',
                });

                if (data) {
                    setStudy(data.study);
                    setStudyWords(data.studyWords);
                }
                setLoading(false);
            };
            fetchStudy();
        } else {
            setLoading(false);
            alert("학습 정보를 찾을 수 없습니다.");
            navigate(-1);
        }
    }, [studyId]);

    if (loading) {
        return <div className="flex items-center justify-center h-screen">Loading...</div>;
    }

    const handleSubmission = async () => {
        if (submitted) {
            return;
        }

        // Validate submissions
        for (let i = 0; i < studyWords.length; i++) {
            const submission = submissions.find(sub => sub.id === studyWords[i].id);
            if (!submission || !submission.submit || submission.submit.trim() === "") {
                if (window.confirm(`슬라이드 ${i + 1}번에 입력된 값이 없습니다. 해당 슬라이드로 이동하시겠습니까?`)) {
                    setCurrentSlide(i);
                    setSubmitLastAnswer(false);
                    return;
                }
                break;
            }
        }

        // Final confirmation if all submissions are valid
        if (window.confirm("모든 항목을 입력했습니다. 바로 제출하시겠습니까?")) {
            try {
                const payload = {
                    studyWords: submissions
                };

                const data = await UseFetchToken(`/api/studies/${study.id}/submit`, {
                    method: "PATCH",
                    body: JSON.stringify(payload)
                });

                if (data && data.studyId) {
                    setSubmitted(true);
                    toast.success("학습이 제출되었습니다!", {
                        position: 'top-center',
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    });

                    // 1초 후 새로고침
                    setTimeout(() => {
                        navigate(`/studies/${study.id}/result`); // Redirect to result page
                    }, 1000);
                }
            } catch (error) {
                console.error('Error:', error);
                alert('학습 제출에 실패했습니다. 다시 시도해주세요.');
            } finally {
                setSubmitLastAnswer(false);
            }
        }
    };

    const handleAnswerSubmission = (studyWordId, answer, entered, timeout = 1000) => {
        setSubmissions((prev) => {
            const updatedSubmissions = prev.filter(sub => sub.id !== studyWordId);
            return [...updatedSubmissions, { id: studyWordId, submit: answer }];
        });
        if (currentSlide === studyWords.length - 1 && entered) {
            if (submitLastAnswer) {
                return;
            }
            setSubmitLastAnswer(true);
            setTimeout(() => {
                handleSubmission();
            }, timeout);
        }
    };

    const nextSlide = () => {
        if (currentSlide < studyWords.length - 1) setCurrentSlide(currentSlide + 1);
    };

    const prevSlide = () => {
        if (currentSlide > 0) setCurrentSlide(currentSlide - 1);
    };

    const goToSlide = (index) => {
        setCurrentSlide(index);
    };

    const isSubmissionEmpty = (index) => {
        const submission = submissions.find(sub => sub.id === studyWords[index].id);
        return !submission || !submission.submit || submission.submit.trim() === "";
    };

    const renderSlide = (word, index) => {
        const slideVisible = currentSlide === index;

        switch (study.type) {
            case StudyType.TRACING.name:
                return (
                    <StudyWordTracing
                        key={word.id}
                        word={word}
                        ignoreCase={study.ignoreCase}
                        onSubmit={handleAnswerSubmission}
                        nextSlide={index < studyWords.length - 1 ? nextSlide : () => {}}
                        slideVisible={slideVisible}
                    />
                );
            case StudyType.WRITING.name:
                return (
                    <StudyWordWriting
                        key={word.id}
                        ignoreCase={study.ignoreCase}
                        word={word}
                        onSubmit={handleAnswerSubmission}
                        nextSlide={index < studyWords.length - 1 ? nextSlide : () => {}}
                        slideVisible={slideVisible}
                        studyClassification={study.classification}
                    />
                );
            case StudyType.SELECT.name:
                return (
                    <StudyWordSelect
                        key={word.id}
                        ignoreCase={study.ignoreCase}
                        word={word}
                        onSubmit={handleAnswerSubmission}
                        nextSlide={index < studyWords.length - 1 ? nextSlide : () => {}}
                        slideVisible={slideVisible}
                        studyClassification={study.classification}
                    />
                );
            default:
                return null;
        }
    };

    const renderPagination = () => {
        const totalSlides = studyWords.length;
        const start = Math.max(
            0,
            Math.min(
                currentSlide - Math.floor(MAX_VISIBLE_BUTTONS / 2),
                totalSlides - MAX_VISIBLE_BUTTONS
            )
        );
        const end = Math.min(start + MAX_VISIBLE_BUTTONS, totalSlides);

        const buttons = [];
        for (let i = start; i < end; i++) {
            let buttonClass = '';
            if (currentSlide === i) {
                buttonClass = 'bg-blue-500 text-white'; // Current slide
            } else if (isSubmissionEmpty(i)) {
                buttonClass = 'bg-gray-400 text-white'; // Slide with empty submission
            } else {
                buttonClass = 'bg-gray-200 text-black'; // Slide with completed submission
            }

            buttons.push(
                <button
                    key={i}
                    className={`mx-1 w-8 h-8 rounded-full ${buttonClass}`}
                    onClick={() => goToSlide(i)}
                >
                    {i + 1}
                </button>
            );
        }

        return (
            <>
                {buttons}
                {end < totalSlides && (
                    <>
                        <span className="mx-1">...</span>
                        <button
                            className={`mx-1 w-8 h-8 rounded-full ${currentSlide === totalSlides - 1 ? 'bg-blue-500 text-white' : isSubmissionEmpty(totalSlides - 1) ? 'bg-gray-400 text-white' : 'bg-gray-200 text-black'}`}
                            onClick={() => goToSlide(totalSlides - 1)}
                        >
                            {totalSlides}
                        </button>
                    </>
                )}
            </>
        );
    };

    return (
        <div className="study-start relative">
            <div className="h-32"></div>
            <ToastContainer />
            <header className="p-4 bg-gray-200">
                <div className="flex justify-between items-center">
                    <button
                        className="text-xl text-red-600 p-2"
                        onClick={() => {
                            if (window.confirm("학습 내역이 저장되지 않습니다. 정말로 떠나시겠습니까?")) {
                                navigate(-1);
                            }
                        }}
                    >
                        X
                    </button>
                </div>
                <div className="flex justify-between items-center p-2">
                    <div className="w-1/4 text-center">학습상태 : {StudyStatus[study.status].value}</div>
                    <div className="w-1/4 text-center">학습유형 : {StudyType[study.type].value}</div>
                    <div className="w-1/4 text-center">학습분류 : {StudyClassification[study.classification].value}</div>
                    <div className="w-1/4 text-center">학습대상 : {StudyTarget[study.target].value}</div>
                </div>
                <h2 className="text-lg font-bold flex justify-center items-center p-2">
                    {study.title}
                </h2>
            </header>
            <div className="carousel-container relative w-full">
                <div className="carousel-inner h-96 overflow-hidden rounded-lg relative">
                    {studyWords.map((word, index) => (
                        <div
                            key={word.id}
                            className={`slide ${currentSlide === index ? 'block' : 'hidden'} absolute w-full h-full flex items-center justify-center`}
                        >
                            {renderSlide(word, index)}
                        </div>
                    ))}
                </div>
                <div className="carousel-controls absolute inset-y-0 left-0 right-0 flex justify-between z-10 pointer-events-none">
                    <button
                        className="text-3xl bg-white bg-opacity-75 p-2 rounded-full ml-2 pointer-events-auto"
                        onClick={prevSlide}
                    >
                        &lt;
                    </button>
                    <button
                        className="text-3xl bg-white bg-opacity-75 p-2 rounded-full mr-2 pointer-events-auto"
                        onClick={nextSlide}
                    >
                        &gt;
                    </button>
                </div>
            </div>
            <div className="pagination-container flex justify-center mt-4">
                {renderPagination()}
            </div>
            {currentSlide === studyWords.length - 1 && (
                <div className="text-center mt-4">
                    <button
                        className="bg-blue-500 text-white py-2 px-4 rounded"
                        onClick={handleSubmission}
                    >
                        제출하기
                    </button>
                </div>
            )}
        </div>
    );
};

export default StudyStart;
