import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { useSearchParams, useNavigate } from 'react-router-dom';
import UseFetchToken from '../hooks/UseFetchToken';
import { StudyType, StudyStatus, StudyTarget, StudyClassification } from "../constants/Constants";

Chart.register(...registerables);

// Helper functions to map API values to constants
const mapValue = (apiValue, constant) => {
    const item = constant.values().find(v => v.name === apiValue);
    return item ? item.value : apiValue;
};

const mapStudyTypes = (apiValue) => mapValue(apiValue, StudyType);
const mapStudyStatuses = (apiValue) => mapValue(apiValue, StudyStatus);
const mapStudyTargets = (apiValue) => mapValue(apiValue, StudyTarget);
const mapStudyClassifications = (apiValue) => mapValue(apiValue, StudyClassification);

// Helper function to generate colors
const generateColors = (count) => {
    const fixedColors = [
        'rgba(102, 255, 153, 0.6)', // Green
        'rgba(54, 162, 235, 0.6)',  // Blue
        'rgba(255, 99, 132, 0.6)',  // Red
        'rgba(255, 205, 86, 0.6)',  // Yellow
        'rgba(153, 102, 255, 0.6)', // Purple
        'rgba(75, 192, 192, 0.6)',  // Teal
    ];
    // If more colors are needed than provided, repeat the array
    return Array.from({ length: count }, (_, i) => fixedColors[i % fixedColors.length]);
};

const Dashboards = () => {
    const navigate = useNavigate();
    const isTeacher = JSON.parse(localStorage.getItem("roles")).includes("ROLE_TEACHER");
    const [searchParams] = useSearchParams();
    const [statistic, setStatistic] = useState(null);
    const [loading, setLoading] = useState(true);
    const [studentId, setStudentId] = useState(searchParams.get('studentId') ? searchParams.get('studentId') : null);

    // 오늘 일자
    // eslint-disable-next-line
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    // 2주 전 일자
    // eslint-disable-next-line
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 14)).toISOString().split('T')[0]);

    useEffect(() => {
        const loadStatistics = async () => {
            try {
                const queryParam = new URLSearchParams();

                if (studentId) {
                    queryParam.append('studentId', studentId);
                }
                if (startDate) {
                    queryParam.append('startDate', startDate);
                }
                if (endDate) {
                    queryParam.append('endDate', endDate);
                }

                const data = await UseFetchToken(`/api/studies/statistic?${queryParam.toString()}`, {
                    method: 'GET',
                });
                setStatistic(data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        loadStatistics();
    }, [studentId, startDate, endDate]);

    if (loading) {
        return <div className="flex items-center justify-center h-screen">Loading...</div>;
    }

    // 1. Data for Study Counts by Target (학습대상)
    const targetColors = generateColors(statistic.targets.length);
    const targetsData = {
        labels: statistic.targets.map(item => mapStudyTargets(item.target)),
        datasets: [
            {
                data: {
                    '한국어': statistic.targets.filter(item => item.target === 'KOREAN')[0].count,
                    '영어': statistic.targets.filter(item => item.target === 'ENGLISH')[0].count,
                },
                backgroundColor: targetColors,
                borderColor: targetColors.map(color => color.replace('0.6', '1')),
                borderWidth: 1,
                barPercentage: 0.5,
            },
        ],
    };

    // 2. Data for Study Counts by Status (학습상태)
    const statusColors = generateColors(statistic.statuses.length);
    const statusesData = {
        labels: statistic.statuses.map(item => mapStudyStatuses(item.status)),
        datasets: [
            {
                data: statistic.statuses.map(item => item.count),
                backgroundColor: statusColors,
                borderColor: statusColors.map(color => color.replace('0.6', '1')),
                borderWidth: 1,
                barPercentage: 0.5,
            },
        ],
    };

    // 3. Data for Study Counts by Type (학습유형)
    const typeColors = generateColors(statistic.types.length);
    const typesData = {
        labels: statistic.types.map(item => mapStudyTypes(item.type)),
        datasets: [
            {
                data: statistic.types.map(item => item.count),
                backgroundColor: typeColors,
                borderColor: typeColors.map(color => color.replace('0.6', '1')),
                borderWidth: 1,
                barPercentage: 0.5,
            },
        ],
    };

    // 4. Data for Study Counts by Classification (학습분류)
    const classificationColors = generateColors(statistic.classifications.length);
    const classificationsData = {
        labels: statistic.classifications.map(item => mapStudyClassifications(item.classification)),
        datasets: [
            {
                data: statistic.classifications.map(item => item.count),
                backgroundColor: classificationColors,
                borderColor: classificationColors.map(color => color.replace('0.6', '1')),
                borderWidth: 1,
                barPercentage: 0.5,
            },
        ],
    };

    // Data for Study Counts by Day
    const studyCountsByDayData = {
        labels: statistic.days.map((item) => new Date(item.studyDate).toLocaleDateString('ko-KR', { month: '2-digit', day: '2-digit' })),
        datasets: [
            {
                label: '학습 횟수',
                data: statistic.days.map((item) => item.studyCount),
                backgroundColor: 'rgba(255, 159, 64, 0.6)',
                borderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 1,
                fill: false,
                tension: 0.3,
            },
        ],
    };

    // Data for Study Word Counts by Day
    const studyWordCountsByDayData = {
        // MM-dd 만 표시
        labels: statistic.days.map((item) => new Date(item.studyDate).toLocaleDateString('ko-KR', { month: '2-digit', day: '2-digit' })),
        datasets: [
            {
                label: '정답 개수',
                data: statistic.days.map((item) => item.correctStudyWordCount),
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
                fill: false,
                tension: 0.3,
            },
            {
                label: '학습한 단어 개수',
                data: statistic.days.map((item) => item.totalStudyWordCount),
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
                fill: false,
                tension: 0.3,
            },
        ],
    };

    const createOldHomeworkTable = (items) => (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-200">
                <tr>
                    <th className="py-2 px-4 text-left text-sm font-semibold text-gray-700">제목</th>
                    <th className="py-2 px-4 text-left text-sm font-semibold text-gray-700">학생</th>
                    <th className="py-2 px-4 text-left text-sm font-semibold text-gray-700">학교</th>
                    <th className="py-2 px-4 text-left text-sm font-semibold text-gray-700">생성일</th>
                </tr>
                </thead>
                <tbody>
                {items.map((item, index) => (
                    <tr
                        key={index}
                        className={`border-t cursor-pointer hover:bg-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}
                        onClick={() => handleOldHomeworkClick(item)}
                    >
                        <td className="py-2 px-4 text-sm text-gray-700">{item.title}</td>
                        <td className="py-2 px-4 text-sm text-gray-700">{item.studentName}</td>
                        <td className="py-2 px-4 text-sm text-gray-700">{item.schoolName} {item.schoolGrade}학년</td>
                        <td className="py-2 px-4 text-sm text-gray-700">
                            {(() => {
                                const date = new Date(item.createdDateTime);
                                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                                const day = ('0' + date.getDate()).slice(-2);
                                return `${month}월 ${day}일`;
                            })()}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );

    function handleOldHomeworkClick(item) {
        if (isTeacher) {
            setStudentId(item.studentId);
            // scroll 을 좀 천천히
            window.scroll({ top: 0, behavior: 'smooth' });
        } else {
            navigate(`/studies/${item.studyId}/start`);
        }
    }

    // Function to create the top student table
    const createTopStudentTable = (items) => (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-200">
                <tr>
                    <th className="py-2 px-4 text-left text-sm font-semibold text-gray-700">학생</th>
                    <th className="py-2 px-4 text-left text-sm font-semibold text-gray-700">학교</th>
                    <th className="py-2 px-4 text-left text-sm font-semibold text-gray-700">학습 횟수</th>
                    <th className="py-2 px-4 text-left text-sm font-semibold text-gray-700">정답률 (%)</th>
                </tr>
                </thead>
                <tbody>
                {items.map((item, index) => (
                    <tr
                        key={index}
                        className={`border-t cursor-pointer hover:bg-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}
                        onClick={() => handleTopStudentClick(item)}
                    >
                        <td className="py-2 px-4 text-sm text-gray-700">{item.studentName}</td>
                        <td className="py-2 px-4 text-sm text-gray-700">{item.schoolName} {item.schoolGrade}학년</td>
                        <td className="py-2 px-4 text-sm text-gray-700">{item.studyCount}회</td>
                        <td className="py-2 px-4 text-sm text-gray-700">{item.correctPercent}%</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );

    // Function to create the old homework table
    function handleTopStudentClick(item) {
        if (isTeacher) {
            setStudentId(item.studentId);
            window.scroll({ top: 0, behavior: 'smooth' });
        } else {
            navigate(`/dashboards`);
        }
    }

    return (
        <div className="p-4">
            <div className="h-32"></div>
            <h1 className="text-2xl font-bold mb-4">학습통계</h1>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {/* 학습대상 */}
                <div className="bg-white p-4 shadow-md rounded-md">
                    <h2 className="text-lg font-semibold mb-2">학습대상</h2>
                    <Bar
                        data={targetsData}
                        options={
                            {
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                            }
                        }
                        className="h-48"
                    />
                </div>

                {/* 학습상태 */}
                <div className="bg-white p-4 shadow-md rounded-md">
                    <h2 className="text-lg font-semibold mb-2">학습상태</h2>
                    <Bar
                        data={statusesData}
                        options={
                            {
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                            }
                        }
                        className="h-48"
                    />
                </div>

                {/* 학습유형 */}
                <div className="bg-white p-4 shadow-md rounded-md">
                    <h2 className="text-lg font-semibold mb-2">학습유형</h2>
                    <Bar
                        data={typesData}
                        options={
                            {
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                            }
                        }
                        className="h-48"
                    />
                </div>

                {/* 학습분류 */}
                <div className="bg-white p-4 shadow-md rounded-md">
                    <h2 className="text-lg font-semibold mb-2">학습분류</h2>
                    <Bar
                        data={classificationsData}
                        options={
                            {
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                            }
                        }
                        className="h-48"
                    />
                </div>

                {/* 일자별 학습 횟수 */}
                <div className="bg-white p-4 shadow-md rounded-md col-span-2">
                    <h2 className="text-lg font-semibold mb-2">일자별 학습 횟수</h2>
                    <Line data={studyCountsByDayData} className="h-48" />
                </div>

                {/* 일자별 학습단어 개수 */}
                <div className="bg-white p-4 shadow-md rounded-md col-span-2">
                    <h2 className="text-lg font-semibold mb-2">일자별 학습단어 개수</h2>
                    <Line data={studyWordCountsByDayData} className="h-48" />
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                <div className="bg-white p-4 shadow-md rounded-md">
                    <h2 className="text-lg font-semibold mb-2">밀린 숙제</h2>
                    {createOldHomeworkTable(statistic.oldHomeworks)}
                </div>

                {statistic.bestAnswerRates && (
                    <div className="bg-white p-4 shadow-md rounded-md">
                        <h2 className="text-lg font-semibold mb-2">성적 상위</h2>
                        {createTopStudentTable(statistic.bestAnswerRates)}
                    </div>
                )}

                {statistic.worstAnswerRates && (
                    <div className="bg-white p-4 shadow-md rounded-md">
                        <h2 className="text-lg font-semibold mb-2">성적 하위</h2>
                        {createTopStudentTable(statistic.worstAnswerRates)}
                    </div>
                )}

                {statistic.bestStudyCounts && (
                    <div className="bg-white p-4 shadow-md rounded-md">
                        <h2 className="text-lg font-semibold mb-2">학습 상위</h2>
                        {createTopStudentTable(statistic.bestStudyCounts)}
                    </div>
                )}

                {statistic.worstStudyCounts && (
                    <div className="bg-white p-4 shadow-md rounded-md">
                        <h2 className="text-lg font-semibold mb-2">학습 하위</h2>
                        {createTopStudentTable(statistic.worstStudyCounts)}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Dashboards;
