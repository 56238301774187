import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { StudyClassification, StudyType } from '../constants/Constants';
import UseFetchToken from "../hooks/UseFetchToken";
import { toast, ToastContainer } from "react-toastify";

const StudyNew = () => {
    const StudyTarget = {
        ENGLISH: { name: "ENGLISH", value: "영어 단어 맞추기" },
        KOREAN: { name: "KOREAN", value: "한글 뜻 맞추기" },
        values() {
            return Object.values(this).filter((v) => typeof v === 'object');
        },
    };

    const navigate = useNavigate();
    const [searchKeyword, setSearchKeyword] = useState('');
    const [privateOnly, setPrivateOnly] = useState(true);
    const [bookResults, setBookResults] = useState([]);
    const [selectedBooks, setSelectedBooks] = useState([]);
    const [studyType, setStudyType] = useState(StudyType.TRACING.name);
    const [studyClassification, setStudyClassification] = useState(StudyClassification.PRACTICE.name);
    const [studyTarget, setStudyTarget] = useState(StudyTarget.KOREAN.name);
    const [ignoreCase, setIgnoreCase] = useState(true);
    const [numberOfWords, setNumberOfWords] = useState(50);
    const [schoolGrade, setSchoolGrade] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const searchTimeoutRef = useRef(null);
    const searchKeywordRef = useRef(searchKeyword);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const dropdownRef = useRef(null);

    const grades = [
        { label: '학년 선택', value: null },
        { label: '초등 1학년', value: 1 },
        { label: '초등 2학년', value: 2 },
        { label: '초등 3학년', value: 3 },
        { label: '초등 4학년', value: 4 },
        { label: '초등 5학년', value: 5 },
        { label: '초등 6학년', value: 6 },
        { label: '중등 1학년', value: 7 },
        { label: '중등 2학년', value: 8 },
        { label: '중등 3학년', value: 9 },
        { label: '고등 1학년', value: 10 },
        { label: '고등 2학년', value: 11 },
        { label: '고등 3학년', value: 12 },
    ];

    const searchBooks = async (keyword) => {
        const size = 100;
        const params = new URLSearchParams({
            page: 1,
            size: size,
            keyword: keyword,
            privateOnly: privateOnly,
        });

        if (schoolGrade !== null && !isNaN(schoolGrade)) {
            params.append('schoolGrade', schoolGrade);
        }

        const data = await UseFetchToken(`/api/books?${params.toString()}`, {
            method: 'GET',
        });
        if (data) {
            setBookResults(data.books || []);
            setIsDropdownVisible(true);
            if (data.pageInfo.total > data.books.length) {
                toast.info(`최대 ${size}건만 조회됩니다.`, {
                    position: 'top-center',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        setIsSearching(false);
    };

    const handleStudyTypeChange = (type) => {
        setStudyType(type);
        if (type === StudyType.TRACING.name) {
            // setStudyClassification(StudyClassification.PRACTICE.name);
        }
    };

    const handleDelayedSearch = (keyword) => {
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }

        setIsSearching(true);
        setBookResults([]);

        searchTimeoutRef.current = setTimeout(() => {
            searchBooks(keyword);
        }, 1000);
    };

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        }

        searchKeywordRef.current = searchKeyword;
        handleDelayedSearch(searchKeywordRef.current);

        return () => {
            if (searchTimeoutRef.current) {
                clearTimeout(searchTimeoutRef.current);
            }
        };
    }, [searchKeyword, schoolGrade, privateOnly]);

    const renderSchoolGradeOptions = () => {
        return grades.map((grade) => (
            <option key={grade.value} value={grade.value}>{grade.label}</option>
        ));
    };

    const handleBookSelect = (book) => {
        if (!selectedBooks.includes(book)) {
            setSelectedBooks([...selectedBooks, book]);
        }
    };

    const handleBookRemove = (bookId) => {
        setSelectedBooks(selectedBooks.filter((book) => book.id !== bookId));
    };

    const handleStudyStart = async () => {
        if (selectedBooks.length === 0) {
            toast.error("교재를 선택해주세요.", {
                position: 'top-center',
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        const studyData = {
            bookIds: selectedBooks.map(book => book.id),
            type: studyType,
            classification: studyClassification,
            target: studyTarget,
            ignoreCase: ignoreCase,
            numberOfWords, // Send selected number of words
        };

        const data = await UseFetchToken('/api/studies', {
            method: 'POST',
            body: JSON.stringify(studyData),
        });

        if (data && data.studyId) {
            toast.success("학습을 시작합니다!", {
                position: 'top-center',
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            setTimeout(() => {
                navigate(`/studies/${data.studyId}/start`);
            }, 1000);
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="max-w-4xl mx-auto p-6 space-y-6">
            <ToastContainer />
            <div className="h-32"></div>
            <h1 className="text-2xl font-bold">새 학습 시작</h1>

            <div className="space-y-4">
                <div className="flex items-center">
                    <div className="w-full">
                        <label className="block mb-1 font-medium text-xl">교재 검색</label>
                        <label className="block my-2">
                            우리 학원 교재만 검색
                            <input
                                type="checkbox"
                                className="ml-2"
                                checked={privateOnly}
                                onChange={(e) => setPrivateOnly(e.target.checked)}
                            />
                        </label>
                        <select
                            className="w-full p-2 border rounded mb-2"
                            value={schoolGrade || ''}
                            onChange={(e) => setSchoolGrade(e.target.value ? Number(e.target.value) : null)}
                            onClick={(e) => {
                                setSchoolGrade(e.target.value ? Number(e.target.value) : null);
                                setIsDropdownVisible(true);
                            }}
                        >
                            {renderSchoolGradeOptions()}
                        </select>
                        <input
                            type="text"
                            className="w-full p-2 border rounded"
                            placeholder="찾으시는 교재를 입력하세요"
                            value={searchKeyword}
                            onChange={(e) => {
                                setSearchKeyword(e.target.value);
                                setIsDropdownVisible(true);
                            }}
                            onClick={() => setIsDropdownVisible(true)}
                        />
                        {isSearching && (
                            <p className="mt-2 text-blue-500">검색 중입니다...</p>
                        )}
                        {isDropdownVisible && (
                            <div className="relative" ref={dropdownRef}>
                                {bookResults.length > 0 ? (
                                    <ul className="absolute z-10 w-full max-h-40 p-2 overflow-y-auto bg-white border border-gray-300 rounded shadow-lg">
                                        {bookResults.map((book) => (
                                            <li
                                                key={book.id}
                                                className="p-2 cursor-pointer hover:bg-gray-200"
                                                onClick={() => handleBookSelect(book)}
                                            >
                                                {book.publisher ? book.publisher + ' - ' : ''}{book.name}{book.chapter ? ' ' + book.chapter : ''}{book.subject ? ' ' + book.subject : ''}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    !isSearching && (
                                        <div className="absolute z-10 w-full p-2 bg-white border border-gray-300 rounded shadow-lg">
                                            {searchKeyword.trim().length === 0 ? (
                                                <p className="text-center text-gray-500">찾으시는 교재를 입력하세요.</p>
                                            ) : (
                                                <p className="text-center text-gray-500">검색 결과가 없습니다.</p>
                                            )}
                                        </div>
                                    )
                                )}
                            </div>
                        )}
                    </div>
                </div>

                {selectedBooks.length > 0 && (
                    <div>
                        <p className="font-semibold">선택된 교재:</p>
                        <ul className="space-y-1">
                            {selectedBooks.map((book) => (
                                <li key={book.id} className="flex items-center">
                                    <span className="mr-2">
                                        {book.publisher ? book.publisher + ' - ' : ''}{book.name}{book.chapter ? ' ' + book.chapter : ''}{book.subject ? ' ' + book.subject : ''}
                                    </span>
                                    <button
                                        className="p-1 text-white bg-red-500 rounded"
                                        onClick={() => handleBookRemove(book.id)}
                                    >
                                        X
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            <div className="space-y-4">
                <div>
                    <p className="font-medium">학습 유형</p>
                    <div className="flex space-x-4">
                        {StudyType.values().map((type) => (
                            <label key={type.name} className="flex items-center">
                                <input
                                    type="radio"
                                    name="studyType"
                                    value={type.name}
                                    className="mr-2"
                                    checked={studyType === type.name}
                                    onChange={() => handleStudyTypeChange(type.name)}
                                />
                                {type.value}
                            </label>
                        ))}
                    </div>
                </div>

                <div>
                    <p className="font-medium">학습 분류</p>
                    <div className="flex space-x-4">
                        {StudyClassification.values().map((classification) => (
                            <label key={classification.name} className="flex items-center">
                                <input
                                    type="radio"
                                    name="studyClassification"
                                    value={classification.name}
                                    className="mr-2"
                                    checked={studyClassification === classification.name}
                                    onChange={() => setStudyClassification(classification.name)}
                                    // disabled={studyType === StudyType.TRACING.name}
                                />
                                {classification.value}
                            </label>
                        ))}
                    </div>
                </div>

                <div>
                    <p className="font-medium">학습 대상</p>
                    <div className="flex space-x-4">
                        {StudyTarget.values().map((target) => (
                            <label key={target.name} className="flex items-center">
                                <input
                                    type="radio"
                                    name="studyTarget"
                                    value={target.name}
                                    className="mr-2"
                                    checked={studyTarget === target.name}
                                    onChange={() => setStudyTarget(target.name)}
                                />
                                {target.value}
                            </label>
                        ))}
                    </div>
                </div>
            </div>

            <div>
                <label className="block mb-1 font-medium">단어 수 선택</label>
                <select
                    className="w-full p-2 border rounded"
                    value={numberOfWords}
                    onChange={(e) => setNumberOfWords(Number(e.target.value))}
                >
                    {[20, 30, 40, 50, 60, 70, 80, 90, 100].map((count) => (
                        <option key={count} value={count}>
                            {count}
                        </option>
                    ))}
                </select>
            </div>

            <div>
                <label className="flex items-center">
                    <input
                        type="checkbox"
                        className="mr-2"
                        checked={ignoreCase}
                        onChange={(e) => setIgnoreCase(e.target.checked)}
                    />
                    대소문자 무시
                </label>
            </div>
            <button
                className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={handleStudyStart}
            >
                학습 시작
            </button>
        </div>
    );
};

export default StudyNew;
