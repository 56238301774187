import UseFetchRefresh from "./UseFetchRefresh";

const API_HOST = `${process.env.REACT_APP_API_HOST}`;

const UseFetchToken = async (endpoint, options = {}) => {

    // defaultHeaders
    const defaultHeaders = {
        'Content-Type': 'application/json',
    };

    // accessToken
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        defaultHeaders['Authorization'] = `Bearer ${accessToken}`;
    }

    // if body is FormData, delete Content-Type header
    if (options.body instanceof FormData) {
        delete defaultHeaders['Content-Type'];
    }

    const config = {
        method: '', // Default method is not set. All methods should be set in options.
        ...options,
        headers: {
            ...defaultHeaders,
            ...options.headers,
        },
    };

    try {
        const response = await fetch(`${API_HOST}${endpoint}`, config);

        if (response.status === 403) {
            alert('접근이 거부되었습니다.');
            window.location.replace("/");
            return;
        }

        if (response.status === 401) {
            const refreshToken = localStorage.getItem('refreshToken');
            if (!refreshToken) {
                alert('로그인이 필요한 서비스입니다.');
                localStorage.clear();
                window.location.replace("/sign-in");
                return;
            }

            const tokenResponse = await fetch(`${API_HOST}/api/users/token/refresh`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${refreshToken}`,
                },
            });

            const tokenData = await tokenResponse.json();

            if (tokenResponse.status === 200 && tokenData.code === '200') {
                localStorage.setItem('accessToken', tokenData.data.accessToken);
                return UseFetchRefresh(endpoint, options); // Retry original request
            } else {
                alert('로그인 시간이 만료되었습니다. 다시 로그인해주세요.');
                localStorage.clear();
                window.location.replace("/sign-in");
                return;
            }
        }

        const responseBody = await response.json();

        if (response.status !== 200) {
            alert(`${responseBody.data}`);
            return;
        }

        if (responseBody.code.startsWith('2')) {
            return responseBody.data;
        } else if (responseBody.code.startsWith('0')) {
            alert(responseBody.data);
        } else {
            alert('요청을 처리할 수 없습니다. 관리자에게 문의해주세요.');
        }
    } catch (error) {
        alert('서버에 문제가 생겼습니다. 관리자에게 문의해주세요.');
        console.error('Error:', error);
    }
};

export default UseFetchToken;
