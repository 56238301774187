import React, { useState, useEffect } from 'react';
import UseFetchToken from "../hooks/UseFetchToken";
import { useNavigate } from "react-router-dom";

const Teacher = () => {
    const navigate = useNavigate();

    const [teacher, setTeacher] = useState({
        loginId: '',
        loginPw: '',
        name: '',
        email: '',
        phone: '',
        street: '',
        addressDetail: '',
        postalCode: '',
    });

    const [isLoginIdChecked, setIsLoginIdChecked] = useState(false);
    const [loginIdCheckMessage, setLoginIdCheckMessage] = useState('로그인 아이디를 입력해주세요.');
    const [isCheckingLoginId, setIsCheckingLoginId] = useState(false);

    const [isEmailChecked, setIsEmailChecked] = useState(false);
    const [emailCheckMessage, setEmailCheckMessage] = useState('이메일을 입력해주세요.');
    const [isCheckingEmail, setIsCheckingEmail] = useState(false);

    const [isPhoneChecked, setIsPhoneChecked] = useState(false);
    const [phoneCheckMessage, setPhoneCheckMessage] = useState('');
    const [isCheckingPhone, setIsCheckingPhone] = useState(false);

    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordConfirmMessage, setPasswordConfirmMessage] = useState('');
    const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false);

    const [teacherValidation, setTeacherValidation] = useState({
        loginId: '로그인 아이디를 입력해주세요.',
        loginPw: '비밀번호를 입력해주세요.',
        name: '이름을 입력해주세요.',
        email: '이메일을 입력해주세요.',
        phone: '',
        postalCode: '',
    });

    const [checkTimeouts, setCheckTimeouts] = useState({
        loginId: null,
        email: null,
        phone: null,
    });

    useEffect(() => {
        validateAllFields();
    }, []);

    const validateAllFields = () => {
        handleDelayedCheck('loginId', teacher.loginId, setIsLoginIdChecked, setLoginIdCheckMessage, setIsCheckingLoginId, () => {}, validateTeacherLoginId);
        handleDelayedCheck('email', teacher.email, setIsEmailChecked, setEmailCheckMessage, setIsCheckingEmail, () => {}, validateTeacherEmail);
        handleDelayedCheck('phone', teacher.phone, setIsPhoneChecked, setPhoneCheckMessage, setIsCheckingPhone, () => {}, validateTeacherPhone);
    };

    const emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTeacher(prevState => ({
            ...prevState,
            [name]: value,
        }));

        if (name === 'loginId') {
            handleDelayedCheck('loginId', value, setIsLoginIdChecked, setLoginIdCheckMessage, setIsCheckingLoginId, checkLoginIdDuplicate, validateTeacherLoginId);
        } else if (name === 'loginPw') {
            const { message } = validateTeacherPassword(value);
            setTeacherValidation((prevState) => ({ ...prevState, loginPw: message }));
        } else if (name === 'name') {
            const { message } = validateTeacherName(value);
            setTeacherValidation((prevState) => ({ ...prevState, name: message }));
        } else if (name === 'email') {
            handleDelayedCheck('email', value, setIsEmailChecked, setEmailCheckMessage, setIsCheckingEmail, checkEmailDuplicate, validateTeacherEmail);
        } else if (name === 'phone' && value) {
            handleDelayedCheck('phone', value, setIsPhoneChecked, setPhoneCheckMessage, setIsCheckingPhone, checkPhoneDuplicate, validateTeacherPhone);
        } else if (name === 'loginPwConfirm') {
            setPasswordConfirm(value);
            const { isValid, message } = validatePasswordConfirm(value, teacher.loginPw);
            setPasswordConfirmMessage(message);
            setIsPasswordConfirmed(isValid);
        } else if (name === 'postalCode') {
            const { message } = validateTeacherPostalCode(value);
            setTeacherValidation((prevState) => ({...prevState, postalCode: message}));
        }
    };

    const handleDelayedCheck = (field, value, setIsChecked, setCheckMessage, setIsChecking, checkFunction, validateFunction) => {
        setIsChecked(false);
        setCheckMessage('');
        setIsChecking(true);
        if (checkTimeouts[field]) {
            clearTimeout(checkTimeouts[field]);
        }
        if (validateFunction) {
            const validationResult = validateFunction(value);
            if (!validationResult.isValid) {
                setCheckMessage(validationResult.message);
                setIsChecked(false);
                setIsChecking(false);
                return;
            }
        }
        const timeout = setTimeout(() => checkFunction(value), 1000);
        setCheckTimeouts({
            ...checkTimeouts,
            [field]: timeout,
        });
    };

    const validateTeacherLoginId = (loginId) => {
        if (!loginId) {
            return { isValid: false, message: '로그인 아이디를 입력해주세요.' };
        }
        if (!/^[a-zA-Z0-9]{6,20}$/.test(loginId)) {
            return { isValid: false, message: '로그인 아이디는 영어와 숫자로 6자에서 20자 사이여야 합니다.' };
        }
        return { isValid: true, message: '' };
    };

    const validateTeacherPassword = (password) => {
        if (!password) {
            return { isValid: false, message: '비밀번호를 입력해주세요.' };
        }
        if (!passwordRegex.test(password)) {
            return { isValid: false, message: '비밀번호는 8자에서 20자 사이여야 하며, 문자, 숫자, 특수문자를 포함해야 합니다.' };
        }
        return { isValid: true, message: '' };
    };

    const validatePasswordConfirm = (confirmPassword, originalPassword) => {
        if (!confirmPassword) {
            return { isValid: false, message: '비밀번호를 한 번 더 입력해주세요.' };
        }
        if (confirmPassword !== originalPassword) {
            return { isValid: false, message: '비밀번호가 일치하지 않습니다.' };
        }
        return { isValid: true, message: '비밀번호가 일치합니다.' };
    };

    const validateTeacherName = (name) => {
        if (!name) {
            return { isValid: false, message: '이름을 입력해주세요.' };
        }
        if (!/^[가-힣a-zA-Z0-9]+$/.test(name)) {
            return { isValid: false, message: '이름은 한글, 영어, 숫자만 가능합니다.' };
        }
        if (name.length < 2 || name.length > 20) {
            return { isValid: false, message: '이름은 2자에서 20자 사이여야 합니다.' };
        }
        return { isValid: true, message: '' };
    };

    const validateTeacherEmail = (email) => {
        if (!email) {
            return { isValid: false, message: '이메일을 입력해주세요.' };
        }
        if (!emailRegex.test(email)) {
            return { isValid: false, message: '유효한 이메일 형식이 아닙니다.' };
        }
        return { isValid: true, message: '' };
    };

    const validateTeacherPhone = (phone) => {
        if (!phone) {
            return { isValid: true, message: '' }; // Phone is optional
        }
        if (!/^010\d{8}$/.test(phone)) {
            return { isValid: false, message: '휴대폰 번호는 010으로 시작하고 11자리여야 합니다.' };
        }
        return { isValid: true, message: '' };
    };

    const validateTeacherPostalCode = (postalCode) => {
        if (!postalCode) {
            return { isValid: true, message: '' }; // Postal code is optional
        }
        if (!/^\d{5}$/.test(postalCode)) {
            return { isValid: false, message: '우편번호는 숫자로 5자리여야 합니다.' };
        }
        return { isValid: true, message: '' };
    };

    const checkLoginIdDuplicate = async (loginId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/users/check-dupl?loginId=${encodeURIComponent(loginId)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const result = await response.json();

            if (result.code === "200") {
                if (result.data.isOk) {
                    setLoginIdCheckMessage('사용 가능한 아이디입니다.');
                    setIsLoginIdChecked(true);
                } else {
                    setLoginIdCheckMessage('이미 사용 중인 아이디입니다.');
                    setIsLoginIdChecked(false);
                }
            } else {
                setLoginIdCheckMessage(result.message);
                setIsLoginIdChecked(false);
            }
        } catch (error) {
            console.error('Error:', error);
            setLoginIdCheckMessage('아이디 중복 검사 중 에러 발생.');
            setIsLoginIdChecked(false);
        } finally {
            setIsCheckingLoginId(false);
        }
    };

    const checkEmailDuplicate = async (email) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/users/check-dupl?email=${encodeURIComponent(email)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const result = await response.json();

            if (result.code === "200") {
                if (result.data.isOk) {
                    setEmailCheckMessage('사용 가능한 이메일입니다.');
                    setIsEmailChecked(true);
                } else {
                    setEmailCheckMessage('이미 사용 중인 이메일입니다.');
                    setIsEmailChecked(false);
                }
            } else {
                setEmailCheckMessage(result.message);
                setIsEmailChecked(false);
            }
        } catch (error) {
            console.error('Error:', error);
            setEmailCheckMessage('이메일 중복 검사 중 에러 발생.');
            setIsEmailChecked(false);
        } finally {
            setIsCheckingEmail(false);
        }
    };

    const checkPhoneDuplicate = async (phone) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/users/check-dupl?phone=${encodeURIComponent(phone)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const result = await response.json();

            if (result.code === "200") {
                if (result.data.isOk) {
                    setPhoneCheckMessage('사용 가능한 연락처입니다.');
                    setIsPhoneChecked(true);
                } else {
                    setPhoneCheckMessage('이미 사용 중인 연락처입니다.');
                    setIsPhoneChecked(false);
                }
            } else {
                setPhoneCheckMessage(result.message);
                setIsPhoneChecked(false);
            }
        } catch (error) {
            console.error('Error:', error);
            setPhoneCheckMessage('연락처 중복 검사 중 에러 발생.');
            setIsPhoneChecked(false);
        } finally {
            setIsCheckingPhone(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // 선생님 정보 유효성 검사
        const requiredFields = [
            { value: teacher.loginId, message: '로그인 아이디를 입력해주세요.' },
            { value: teacher.loginPw, message: '비밀번호를 입력해주세요.' },
            { value: teacher.name, message: '이름을 입력해주세요.' },
            { value: teacher.email, message: '이메일을 입력해주세요.' },
        ];

        for (const field of requiredFields) {
            if (!field.value) {
                alert(field.message);
                return;
            }
        }

        if (!validateTeacherLoginId(teacher.loginId).isValid) {
            alert('로그인 아이디를 확인해주세요.');
            return;
        }

        if (!validateTeacherPassword(teacher.loginPw).isValid) {
            alert('비밀번호를 확인해주세요.');
            return;
        }

        if (!isPasswordConfirmed) {
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }

        if (!validateTeacherName(teacher.name).isValid) {
            alert('선생님 이름을 확인해주세요.');
            return;
        }

        if (!validateTeacherEmail(teacher.email).isValid) {
            alert('선생님 이메일을 확인해주세요.');
            return;
        }

        if (!validateTeacherPhone(teacher.phone).isValid) {
            alert('선생님 휴대폰 번호를 확인해주세요.');
            return;
        }

        if (!validateTeacherPostalCode(teacher.postalCode).isValid) {
            alert('선생님 우편번호를 확인해주세요.');
            return;
        }

        // 선생님 정보 중복 검사
        if (!isLoginIdChecked) {
            alert('로그인 아이디 중복 검사를 완료해주세요.');
            return;
        }

        if (!isEmailChecked) {
            alert('이메일 중복 검사를 완료해주세요.');
            return;
        }

        if (teacher.phone && !isPhoneChecked) {
            alert('휴대폰 번호 중복 검사를 완료해주세요.');
            return;
        }

        // phone 에 값이 없을 경우 아예 phone 을 null 로 지정한다.
        if (!teacher.phone) {
            teacher.phone = null;
        }

        // postalCode 에 값이 없을 경우 아예 postalCode 를 null 로 지정한다.
        if (!teacher.postalCode) {
            teacher.postalCode = null;
        }

        const formData = {
            loginId: teacher.loginId,
            loginPw: teacher.loginPw,
            name: teacher.name,
            email: teacher.email,
            phone: teacher.phone,
            street: teacher.street,
            addressDetail: teacher.addressDetail,
            postalCode: teacher.postalCode,
        };

        try {
            const data = await UseFetchToken(`/api/teachers`, {
                method: 'POST',
                body: JSON.stringify(formData),
            });

            if (data) {
                alert('인증 메일을 발송했습니다. 인증 후 사용이 가능합니다.');
                navigate('/teachers');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4 p-6">
            <div className="h-32"></div>
            <h2 className="text-2xl font-bold">선생님 정보</h2>
            <div>
                <label className="block mb-2">로그인 아이디*</label>
                <input
                    autoComplete="off"
                    onPaste={(e) => e.preventDefault()}  // Prevent paste
                    type="text"
                    name="loginId"
                    value={teacher.loginId}
                    onChange={handleInputChange}
                    className="block w-full p-2 border border-gray-300 rounded"
                    placeholder="필수 입력 항목"
                    required
                />
                {isCheckingLoginId ? (
                    <p className="mt-2 text-blue-500">중복 검사 중...</p>
                ) : (
                    <p className={`mt-2 ${isLoginIdChecked ? 'text-green-500' : 'text-red-500'}`}>
                        {loginIdCheckMessage}
                    </p>
                )}
            </div>
            <div>
                <label className="block mb-2">비밀번호*</label>
                <input
                    autoComplete="off"
                    onPaste={(e) => e.preventDefault()}  // Prevent paste
                    type="password"
                    name="loginPw"
                    value={teacher.loginPw}
                    onChange={handleInputChange}
                    className="block w-full p-2 border border-gray-300 rounded"
                    placeholder="필수 입력 항목"
                    required
                />
                <p className={`mt-2 ${teacherValidation.loginPw ? 'text-red-500' : 'text-green-500'}`}>
                    {teacherValidation.loginPw}
                </p>
            </div>
            <div>
                <label className="block mb-2">비밀번호 확인*</label>
                <input
                    autoComplete="off"
                    onPaste={(e) => e.preventDefault()}  // Prevent paste
                    type="password"
                    name="loginPwConfirm"
                    value={passwordConfirm}
                    onChange={handleInputChange}
                    className="block w-full p-2 border border-gray-300 rounded"
                    placeholder="필수 입력 항목"
                    required
                />
                <p className={`mt-2 ${isPasswordConfirmed ? 'text-green-500' : 'text-red-500'}`}>
                    {passwordConfirmMessage}
                </p>
            </div>
            <div>
                <label className="block mb-2">이름*</label>
                <input
                    autoComplete="off"
                    onPaste={(e) => e.preventDefault()}  // Prevent paste
                    type="text"
                    name="name"
                    value={teacher.name}
                    onChange={handleInputChange}
                    className="block w-full p-2 border border-gray-300 rounded"
                    placeholder="필수 입력 항목"
                    required
                />
                <p className={`mt-2 ${teacherValidation.name ? 'text-red-500' : 'text-green-500'}`}>
                    {teacherValidation.name}
                </p>
            </div>
            <div>
                <label className="block mb-2">이메일*</label>
                <input
                    autoComplete="off"
                    onPaste={(e) => e.preventDefault()}  // Prevent paste
                    type="email"
                    name="email"
                    value={teacher.email}
                    onChange={handleInputChange}
                    className="block w-full p-2 border border-gray-300 rounded"
                    placeholder="필수 입력 항목"
                    required
                />
                {isCheckingEmail ? (
                    <p className="mt-2 text-blue-500">중복 검사 중...</p>
                ) : (
                    <p className={`mt-2 ${isEmailChecked ? 'text-green-500' : 'text-red-500'}`}>
                        {emailCheckMessage}
                    </p>
                )}
            </div>
            <div>
                <label className="block mb-2">연락처</label>
                <input
                    autoComplete="off"
                    onPaste={(e) => e.preventDefault()}  // Prevent paste
                    type="text"
                    name="phone"
                    value={teacher.phone}
                    onChange={handleInputChange}
                    className="block w-full p-2 border border-gray-300 rounded"
                />
                {teacher.phone && (
                    isCheckingPhone ? (
                        <p className="mt-2 text-blue-500">중복 검사 중...</p>
                    ) : (
                        <p className={`mt-2 ${isPhoneChecked ? 'text-green-500' : 'text-red-500'}`}>
                            {phoneCheckMessage}
                        </p>
                    )
                )}
            </div>
            <div>
                <label className="block mb-2">주소</label>
                <input
                    autoComplete="off"
                    onPaste={(e) => e.preventDefault()}  // Prevent paste
                    type="text"
                    name="street"
                    value={teacher.street}
                    onChange={handleInputChange}
                    className="block w-full p-2 border border-gray-300 rounded"
                />
            </div>
            <div>
                <label className="block mb-2">상세주소</label>
                <input
                    autoComplete="off"
                    onPaste={(e) => e.preventDefault()}  // Prevent paste
                    type="text"
                    name="addressDetail"
                    value={teacher.addressDetail}
                    onChange={handleInputChange}
                    className="block w-full p-2 border border-gray-300 rounded"
                />
            </div>
            <div>
                <label className="block mb-2">우편번호</label>
                <input
                    autoComplete="off"
                    onPaste={(e) => e.preventDefault()}  // Prevent paste
                    type="text"
                    name="postalCode"
                    value={teacher.postalCode}
                    onChange={handleInputChange}
                    className="block w-full p-2 border border-gray-300 rounded"
                />
                <p className={`mt-2 ${teacherValidation.postalCode ? 'text-red-500' : 'text-green-500'}`}>
                    {teacherValidation.postalCode}
                </p>
            </div>
            <button type="submit" className="mt-4 bg-blue-500 text-white p-2 rounded">등록</button>
        </form>
    );
};

export default Teacher;
