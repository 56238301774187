import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import UseFetchToken from '../hooks/UseFetchToken';
import {StudyClassification, StudyStatus, StudyTarget, StudyType} from "../constants/Constants";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Studies = () => {
    const navigate = useNavigate();
    const [studies, setStudies] = useState([]);
    const [lastSequence, setLastSequence] = useState(null);
    const [hasNext, setHasNext] = useState(true);
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [inputParams, setInputParams] = useState({
        startDateTime: '',
        endDateTime: '',
        studyTitle: '',
        studentName: '',
        schoolGrade: '',
        studyStatus: '',
    });
    const loaderRef = useRef(null);
    const isTeacher = JSON.parse(localStorage.getItem("roles")).includes("ROLE_TEACHER");

    const fetchStudies = async (lastSeq, params = {}) => {
        setLoading(true);
        if (!lastSeq) {
            lastSeq = '';
        }

        const queryParams = new URLSearchParams({
            size: 20,
            lastSequence: lastSeq,
            ...params,
            startDateTime: params.startDateTime ? `${params.startDateTime}T00:00:00` : '',
            endDateTime: params.endDateTime ? `${params.endDateTime}T23:59:59` : '',
        });

        try {
            const data = await UseFetchToken(`/api/studies?${queryParams.toString()}`, {
                method: 'GET',
            });

            if (data) {
                setStudies((prevStudies) => [...prevStudies, ...data.studies]);
                setLastSequence(data.slicingInfo.lastSequence);
                setHasNext(data.slicingInfo.hasNext);

                if (!data.slicingInfo.hasNext) {
                    toast.success("마지막 항목까지 모두 조회했습니다.", {
                        position: 'top-center',
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
        setPageLoading(false);
        setLoading(false);
    };

    useEffect(() => {
        fetchStudies(null, inputParams);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasNext && !loading) {
                    fetchStudies(lastSequence, inputParams);
                }
            },
            { threshold: 0.1 }
        );

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [lastSequence, hasNext, loading, inputParams]);

    if (pageLoading) {
        return <div className="flex items-center justify-center h-screen">Loading...</div>;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputParams((prevParams) => ({
            ...prevParams,
            [name]: value,
        }));
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setStudies([]);
        setLastSequence(null);
        fetchStudies(null, inputParams);
    };

    const handleStudyClick = (id, status) => {
        if (status === StudyStatus.SUBMITTED.name || isTeacher) {
            navigate(`/studies/${id}/result`);
        } else {
            navigate(`/studies/${id}/start`);
        }
    };

    const renderSchoolGradeOptions = () => {
        const grades = [
            { label: '초등1', value: 1 },
            { label: '초등2', value: 2 },
            { label: '초등3', value: 3 },
            { label: '초등4', value: 4 },
            { label: '초등5', value: 5 },
            { label: '초등6', value: 6 },
            { label: '중등1', value: 7 },
            { label: '중등2', value: 8 },
            { label: '중등3', value: 9 },
            { label: '고등1', value: 10 },
            { label: '고등2', value: 11 },
            { label: '고등3', value: 12 },
        ];
        return grades.map((grade) => (
            <option key={grade.value} value={grade.value}>{grade.label}</option>
        ));
    };

    const findSchoolGradeLables = (schoolGrade) => {
        const grades = [
            { label: '1학년', value: 1 },
            { label: '2학년', value: 2 },
            { label: '3학년', value: 3 },
            { label: '4학년', value: 4 },
            { label: '5학년', value: 5 },
            { label: '6학년', value: 6 },
            { label: '1학년', value: 7 },
            { label: '2학년', value: 8 },
            { label: '3학년', value: 9 },
            { label: '1학년', value: 10 },
            { label: '2학년', value: 11 },
            { label: '3학년', value: 12 },
        ];
        return grades.filter((grade) => grade.value === schoolGrade).map((grade) => (grade.label));
    };

    const renderStatusOptions = () => {
        return StudyStatus.values().filter((s) => s.name !== StudyStatus.DELETED.name).map((status) => (
            <option key={status.name} value={status.name}>{status.value}</option>
        ));
    };

    const findStudyStatusValue = (studyStatus) => {
        return StudyStatus.values().filter((s) => s.name === studyStatus).map((status) => (
            <span
                key={status.name}
                value={status.name}
                className={status.name === StudyStatus.SUBMITTED.name ? "" : "font-bold text-blue-500"}
            >
                {status.value}
            </span>
        ));
    };

    return (
        <div className="p-6 max-w-full px-56 mx-auto">
            <ToastContainer/>
            <div className="h-32"></div>
            <h1 className="text-2xl font-bold mb-4">학습 목록</h1>

            <form onSubmit={handleSearchSubmit} className="mb-4 space-y-4">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-700">학습 제목</label>
                        <input
                            autoComplete="off"
                            type="text"
                            name="studyTitle"
                            value={inputParams.studyTitle}
                            onChange={handleInputChange}
                            placeholder="학습 제목"
                            className="p-2 border border-gray-300 rounded w-full"
                        />
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-700">학습 상태</label>
                        <select
                            name="studyStatus"
                            value={inputParams.studyStatus}
                            onChange={handleInputChange}
                            className="p-2 border border-gray-300 rounded w-full"
                        >
                            <option value="">전체</option>
                            {renderStatusOptions()}
                        </select>
                    </div>
                    {isTeacher && (
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-700">학생 이름</label>
                            <input
                                autoComplete="off"
                                type="text"
                                name="studentName"
                                value={inputParams.studentName}
                                onChange={handleInputChange}
                                placeholder="학생 이름"
                                className="p-2 border border-gray-300 rounded w-full"
                            />
                        </div>
                    )}
                    {isTeacher && (
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-700">학년</label>
                            <select
                                name="schoolGrade"
                                value={inputParams.schoolGrade}
                                onChange={handleInputChange}
                                className="p-2 border border-gray-300 rounded w-full"
                            >
                                <option value="">학년 선택</option>
                                {renderSchoolGradeOptions()}
                            </select>
                        </div>
                    )}
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-700">검색 시작일</label>
                        <input
                            type="date"
                            name="startDateTime"
                            value={inputParams.startDateTime}
                            onChange={handleInputChange}
                            className="p-2 border border-gray-300 rounded w-full"
                        />
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-700">검색 종료일</label>
                        <input
                            type="date"
                            name="endDateTime"
                            value={inputParams.endDateTime}
                            onChange={handleInputChange}
                            className="p-2 border border-gray-300 rounded w-full"
                        />
                    </div>
                </div>
                <button
                    type="submit"
                    className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 w-full"
                >
                    검색
                </button>
            </form>

            {studies.length === 0 && !loading && (
                <div className="text-center py-4">
                    조회 결과가 없습니다.
                </div>
            )}

            {studies.length > 0 && (
                <ul className="space-y-4">
                    {studies.map((study) => (
                        <li
                            key={study.id}
                            onClick={() => handleStudyClick(study.id, study.status)}
                            className="p-4 border rounded cursor-pointer hover:bg-gray-100"
                        >
                            <h2 className="text-lg font-semibold">{study.title}</h2>
                            {isTeacher && (
                                <p>학생 정보
                                    : {study.studentName}({study.schoolName} {findSchoolGradeLables(study.schoolGrade)})</p>
                            )}
                            <p>학습 정보
                                : {StudyTarget[study.target].value} {StudyType[study.type].value} {StudyClassification[study.classification].value}</p>
                            <p>학습 상태
                                : {findStudyStatusValue(study.status)} {study.status === StudyStatus.SUBMITTED.name && (
                                    <span>
                                        <span className={`
                                        ${study.correctCount === study.totalCount
                                            ? "font-extrabold text-blue-500"
                                            : study.correctCount / study.totalCount < 0.4
                                                ? "font-extrabold text-red-500"
                                                : ""
                                        }`}>
                                            {study.correctCount}
                                        </span>
                                        &nbsp;/ {study.totalCount}
                                    </span>
                                )}
                            </p>

                            <p>학습 생성일 :&nbsp;
                                {(() => {
                                    const date = new Date(study.createdDateTime);
                                    const year = date.getFullYear();
                                    const month = ('0' + (date.getMonth() + 1)).slice(-2);
                                    const day = ('0' + date.getDate()).slice(-2);
                                    return `${year}년 ${month}월 ${day}일`;
                                })()}</p>
                        </li>
                    ))}
                </ul>
            )}

            {hasNext && studies.length > 0 && (
                <div ref={loaderRef} className="text-center py-4">
                    {loading ? '교재를 검색 중입니다...' : ''}
                </div>
            )}

            {!hasNext && studies.length > 0 && (
                <div className="text-center py-4">마지막 항목까지 모두 조회했습니다.</div>
            )}
        </div>
    );
};

export default Studies;
