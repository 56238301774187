
const UseFetchRefresh = async (endpoint, options = {}) => {

    // API_HOST
    const API_HOST = `${process.env.REACT_APP_API_HOST}`;

    // defaultHeaders
    const defaultHeaders = {
        'Content-Type': 'application/json',
    };

    // accessToken
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        defaultHeaders['Authorization'] = `Bearer ${accessToken}`;
    }

    // if body is FormData, delete Content-Type header
    if (options.body instanceof FormData) {
        delete defaultHeaders['Content-Type'];
    }

    const config = {
        method: '', // Default method is not set. All methods should be set in options.
        ...options,
        headers: {
            ...defaultHeaders,
            ...options.headers,
        },
    };

    try {
        const response = await fetch(`${API_HOST}${endpoint}`, config);

        if (response.status === 403) {
            alert('접근이 거부되었습니다.');
            window.location.replace("/");
            return;
        }

        // accessToken was surely expired
        if (response.status === 401) {
            alert('로그인이 필요한 서비스입니다.');
            localStorage.clear();
            window.location.replace("/sign-in");
            return;
        }

        const responseBody = await response.json();

        if (response.status !== 200) {
            alert(`${responseBody.data}`);
            return;
        }

        if (responseBody.code.startsWith('2')) {
            return responseBody.data;
        } else if (responseBody.code.startsWith('0')) {
            alert(responseBody.data);
        } else {
            alert('요청을 처리할 수 없습니다. 관리자에게 문의해주세요.');
        }
    } catch (error) {
        alert('서버에 문제가 생겼습니다. 관리자에게 문의해주세요.');
        console.error('Error:', error);
    }
};

export default UseFetchRefresh;
