import React, { useState, useEffect } from 'react';

const Academies = () => {
    const [academies, setAcademies] = useState([]);
    const [pageInfo, setPageInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchParams, setSearchParams] = useState({
        page: 1,
        size: 10,
        academyName: '',
        academyAddress: '',
    });
    const [inputParams, setInputParams] = useState({
        academyName: '',
        academyAddress: '',
    });
    const [total, setTotal] = useState(null);
    const [searchType, setSearchType] = useState('academyName'); // 검색 타입 (이름 또는 주소)

    const fetchAcademies = async (params) => {
        setLoading(true);
        setError(null);

        const queryParams = {
            page: params.page,
            size: params.size,
            ...(params.academyName && { academyName: params.academyName }),
            ...(params.academyAddress && { academyAddress: params.academyAddress }),
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${process.env.REACT_APP_API_HOST}/api/academies?${queryString}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const result = await response.json();
                setAcademies(result.data.academies);
                setPageInfo(result.data.pageInfo);
                setTotal(result.data.total);
            } else {
                setError('학원 목록을 불러오는 데 실패했습니다.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('학원 목록을 불러오는 중 오류가 발생했습니다.');
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchAcademies(searchParams);
    }, [searchParams]);

    if (loading) {
        return <div className="flex items-center justify-center h-screen">Loading...</div>;
    }

    if (error) {
        return <div className="flex items-center justify-center h-screen text-red-500">{error}</div>;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputParams((prevParams) => ({
            ...prevParams,
            [name]: value,
        }));
    };

    const handleSearchTypeChange = (e) => {
        const newSearchType = e.target.value;
        setSearchType(newSearchType);

        // 선택한 검색 타입에 따라 다른 입력 필드의 값을 null로 설정
        if (newSearchType === 'academyName') {
            setInputParams((prevParams) => ({
                ...prevParams,
                academyName: '',
                academyAddress: null,
            }));
        } else if (newSearchType === 'academyAddress') {
            setInputParams((prevParams) => ({
                ...prevParams,
                academyName: null,
                academyAddress: '',
            }));
        }
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setSearchParams((prevParams) => ({
            ...prevParams,
            academyName: inputParams.academyName,
            academyAddress: inputParams.academyAddress,
            page: 1, // 검색 조건 변경 시 첫 페이지로 이동
        }));
    };

    const handlePageChange = (newPage) => {
        setSearchParams((prevParams) => ({
            ...prevParams,
            page: newPage,
        }));
    };

    const handlePageSetChange = (direction) => {
        const startPage = Math.floor((pageInfo.page - 1) / 10) * 10 + 1;
        const newPage = direction === 'first' ? 1 :
            direction === 'last' ? pageInfo.lastPage :
                direction === 'prev' ? Math.max(1, startPage - 10) :
                    Math.min(pageInfo.lastPage, startPage + 10);

        setSearchParams((prevParams) => ({
            ...prevParams,
            page: newPage,
        }));
    };

    const renderPagination = () => {
        const startPage = Math.floor((pageInfo.page - 1) / 10) * 10 + 1;
        const endPage = Math.min(startPage + 9, pageInfo.lastPage);
        const pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        const isFirstSet = pageInfo.page <= 10;
        const isLastSet = endPage === pageInfo.lastPage;

        return (
            <div className="mt-4 flex justify-center space-x-2">
                <button
                    onClick={() => handlePageSetChange('first')}
                    disabled={isFirstSet}
                    className={`px-2 py-1 bg-blue-500 text-white rounded ${isFirstSet && 'opacity-50 cursor-not-allowed'}`}
                >
                    &lt;&lt;
                </button>
                <button
                    onClick={() => handlePageSetChange('prev')}
                    disabled={isFirstSet}
                    className={`px-2 py-1 bg-blue-500 text-white rounded ${isFirstSet && 'opacity-50 cursor-not-allowed'}`}
                >
                    &lt;
                </button>
                {pageNumbers.map((number) => (
                    <button
                        key={number}
                        onClick={() => handlePageChange(number)}
                        className={`px-2 py-1 ${number === pageInfo.page ? 'bg-blue-700 text-white' : 'bg-blue-500 text-white'} rounded`}
                    >
                        {number}
                    </button>
                ))}
                <button
                    onClick={() => handlePageSetChange('next')}
                    disabled={isLastSet}
                    className={`px-2 py-1 bg-blue-500 text-white rounded ${isLastSet && 'opacity-50 cursor-not-allowed'}`}
                >
                    &gt;
                </button>
                <button
                    onClick={() => handlePageSetChange('last')}
                    disabled={isLastSet}
                    className={`px-2 py-1 bg-blue-500 text-white rounded ${isLastSet && 'opacity-50 cursor-not-allowed'}`}
                >
                    &gt;&gt;
                </button>
            </div>
        );
    };

    return (
        <div>
            <div className="h-32"></div>
            <div className="p-6">
                <h2 className="text-2xl font-bold mb-10 text-center">학원 목록</h2>
                {total &&
                    <div className="mb-4 text-center">
                        오늘까지 {total}개의 학원이 함께 하고 있습니다
                        <span className="relative group">
                            &nbsp;<span>(?)</span>
                            <span className="invisible group-hover:visible absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 z-10 whitespace-nowrap">
                                비공개 학원을 포함한 수입니다.
                                <svg className="absolute text-gray-800 h-2 w-full left-0 top-full" viewBox="0 0 255 255">
                                    <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
                                </svg>
                            </span>
                        </span>
                    </div>}
                <div className="max-w-full px-56 mx-auto">
                    <form onSubmit={handleSearchSubmit} className="mb-4 space-y-4">
                        <div className="flex justify-end items-center space-x-4">
                            <div className="flex items-center space-x-2">
                                <select
                                    value={searchType}
                                    onChange={handleSearchTypeChange}
                                    className="p-2 border border-gray-300 rounded"
                                >
                                    <option value="academyName">이름으로 검색</option>
                                    <option value="academyAddress">주소로 검색</option>
                                </select>
                                <input
                                    type="text"
                                    name={searchType}
                                    value={inputParams[searchType] || ''} // 값이 null일 경우 빈 문자열 처리
                                    onChange={handleInputChange}
                                    placeholder={searchType === 'academyName' ? '학원 이름 검색' : '학원 주소 검색'}
                                    className="p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <button type="submit" className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">검색</button>
                        </div>
                    </form>
                    <table className="min-w-full bg-white border border-gray-300">
                        <thead>
                        <tr>
                            <th className="py-2 px-4 border-b text-base font-bold bg-blue-500 text-white">학원 이름</th>
                            <th className="py-2 px-4 border-b text-base font-bold bg-blue-500 text-white">주소</th>
                            <th className="py-2 px-4 border-b text-center text-base font-bold bg-blue-500 text-white">전화번호</th>
                            <th className="py-2 px-4 border-b text-base font-bold bg-blue-500 text-white">이메일</th>
                            <th className="py-2 px-2 border-b text-center text-base font-bold bg-blue-500 text-white">가입일</th>
                        </tr>
                        </thead>
                        <tbody>
                        {academies.map((academy) => {
                            return (
                                <tr key={academy.id} className="hover:bg-gray-100">
                                    <td className="py-2 px-4 border-b text-center">{academy.name}</td>
                                    <td className="py-2 px-4 border-b text-center">
                                        {(() => {
                                            const { street, addressDetail, postalCode } = academy;
                                            let address = street;
                                            if (addressDetail.trim()) {
                                                address += `, ${addressDetail}`;
                                            }
                                            if (postalCode) {
                                                address += `(${postalCode})`;
                                            }
                                            if (!address) {
                                                address = '주소 없음';
                                            }
                                            return address;
                                        })()}
                                    </td>
                                    <td className="py-2 px-4 border-b text-center">{academy.phone}</td>
                                    <td className="py-2 px-4 border-b text-center">{academy.email}</td>
                                    <td className="py-2 px-2 border-b text-center">
                                        {(() => {
                                            const date = new Date(academy.createdDateTime);
                                            const year = date.getFullYear();
                                            const month = ('0' + (date.getMonth() + 1)).slice(-2);
                                            const day = ('0' + date.getDate()).slice(-2);
                                            return `${year}년 ${month}월 ${day}일`;
                                        })()}
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    {renderPagination()}
                </div>
            </div>
        </div>
    );
};

export default Academies;
