import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../components/UserContext';

function Header() {
    const { user, logout } = useContext(UserContext);
    const [nav, setNav] = useState([]);

    // 선생의 경우
    //  - 학습관리
    //    - 학습통계
    //    - 학습내역
    //    - 교재목록
    //    - 숙제등록
    //    - 시험지출력
    //  - 학원관리
    //    - 학생목록
    //    - 선생목록
    //    - 학원정보(원장님만)

    // 학생의 경우
    //  - 학습상태
    //  - 학습시작
    //  - 학습기록

    // 비회원인 경우
    //  - 이용가이드
    //  - 이용약관
    //  - 참여학원

    const updateNav = () => {
        let items;
        if (user.roles.includes('ROLE_DIRECTOR')) {
            items = [
                { name: '학습현황', link: '/dashboards' },
                { name: '학습내역', link: '/studies' },
                { name: '숙제등록', link: '/studies/assign' },
                { name: '시험지출력', link: '/studies/print' },
                { name: '교재목록', link: '/books' },
                { name: '학생목록', link: '/students' },
                { name: '선생목록', link: '/teachers' },
                { name: '학원정보', link: `/academies/${user.academyId}` },
            ];
        } else if (user.roles.includes('ROLE_TEACHER')) {
            items = [
                { name: '학습현황', link: '/dashboards' },
                { name: '학습내역', link: '/studies' },
                { name: '숙제등록', link: '/studies/assign' },
                { name: '시험지출력', link: '/studies/print' },
                { name: '교재목록', link: '/books' },
                { name: '학생목록', link: '/students' },
                { name: '선생목록', link: '/teachers' },
            ];
        } else if (user.roles.includes('ROLE_STUDENT')) {
            items = [
                { name: '학습상태', link: '/dashboards' },
                { name: '학습시작', link: '/studies/new' },
                { name: '학습기록', link: '/studies' },
            ];
        } else {
            items = [
                { name: '이용가이드', link: '/tutorial' },
                { name: '이용약관', link: '/terms' },
                { name: '참여학원', link: '/academies' },
            ];
        }
        setNav(items);
    };

    useEffect(() => {
        updateNav();
    }, [user]);

    return (
        <header className="flex items-center justify-between h-24 px-4 bg-white shadow-sm fixed w-full z-50">
            <Link className="flex items-center gap-2" to="/">
                <svg width="50" height="50">
                    {/* SVG code here */}
                </svg>
                <span>Brand Name</span>
            </Link>
            <nav>
                <ul className="flex items-center gap-10">
                    {nav.map((item, index) => (
                        <li key={index}>
                            <Link className="text-2xl text-gray-500 hover:text-gray-700 transition-colors" to={item.link}>
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            <div className="flex items-center gap-4">
                {user.isLoggedIn ? (
                    <>
                        <Link to="/my-page">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                마이페이지
                            </button>
                        </Link>
                        <Link to="/">
                            <button
                                onClick={logout}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                                로그아웃
                            </button>
                        </Link>
                    </>
                ) : (
                    <>
                        <Link to="/sign-in">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                로그인
                            </button>
                        </Link>
                        <Link to="/sign-up">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                회원가입
                            </button>
                        </Link>
                    </>
                )}
            </div>
        </header>
    );
}

export default Header;
