import React, { useEffect } from 'react';
import MyPageTeacher from '../fragments/MyPageTeacher';
import MyPageStudent from '../fragments/MyPageStudent';
import { useNavigate } from "react-router-dom";


const MyPage = () => {
    const navigate = useNavigate();
    const roles = JSON.parse(localStorage.getItem('roles'));

    useEffect(() => {
        if (!roles) {
            localStorage.clear();
            alert('로그인이 필요한 서비스입니다.');
            navigate('/sign-in');
        }
    }, [roles, navigate]);

    const isTeacher = roles.includes('ROLE_TEACHER');
    return isTeacher ? (
        <MyPageTeacher />
    ) : (
        <MyPageStudent />
    );
};

export default MyPage;