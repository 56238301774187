import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from '../layouts/Header';
import Academies from '../pages/Academies';
import SignUp from '../pages/SignUp';
import SignIn from '../pages/SignIn';
import Academy from '../pages/Academy';
import Teachers from '../pages/Teachers';
import Teacher from "../pages/Teacher";
import Students from '../pages/Students';
import Student from '../pages/Student';
import Books from '../pages/Books';
import Book from '../pages/Book';
import MyPage from '../pages/MyPage';
import Studies from "../pages/Studies";
import HelpId from "../pages/HelpId";
import HelpPw from "../pages/HelpPw";
import EndTrial from "../pages/EndTrial";
import StudyNew from "../pages/StudyNew";
import StudyStart from "../pages/StudyStart";
import StudyResult from "../pages/StudyResult";
import StudyAssign from "../pages/StudyAssign";
import Dashboards from "../pages/Dashboards";
import TestPrint from "../pages/TestPrint";
import AcademyVerify from "../pages/AcademyVerify";
import TeacherVerify from "../pages/TeacherVerify";
import Tutorial from "../pages/Tutorial";
import Terms from "../pages/Terms";

const AppRoutes = () => {
    return (
        <Router>
            <MainRoutes />
        </Router>
    );
};

const MainRoutes = () => {
    return (
        <div>
            <Header />
            <Routes>
                <Route exact path="/academies" element={<Academies />} />
                <Route exact path="/academies/:academyId" element={<Academy />} />
                <Route exact path="/academies/:academyId/verify" element={<AcademyVerify />} />
                <Route exact path="/teachers" element={<Teachers />} />
                <Route exact path="/teachers/new" element={<Teacher />} />
                <Route exact path="/teachers/:teacherId/verify" element={<TeacherVerify />} />
                <Route exact path="/students" element={<Students />} />
                <Route exact path="/students/new" element={<Student />} />
                <Route exact path="/students/:studentId" element={<Student />} />
                <Route exact path="/books" element={<Books />} />
                <Route exact path="/books/new" element={<Book />} />
                <Route exact path="/books/:bookId" element={<Book />} />
                <Route exact path="/studies" element={<Studies />} />
                <Route exact path="/studies/new" element={<StudyNew />} />
                <Route exact path="/studies/:studyId/start" element={<StudyStart />} />
                <Route exact path="/studies/:studyId/result" element={<StudyResult />} />
                <Route exact path="/studies/assign" element={<StudyAssign />} />
                <Route exact path="/dashboards" element={<Dashboards />} />
                <Route exact path="/studies/print" element={<TestPrint />} />
                <Route exact path="/my-page" element={<MyPage />} />
                <Route exact path="/sign-up" element={<SignUp />} />
                <Route exact path="/sign-in" element={<SignIn />} />
                <Route exact path="/help/id" element={<HelpId />} />
                <Route exact path="/help/pw" element={<HelpPw />} />
                <Route exact path="/end-trial" element={<EndTrial />} />
                <Route exact path="/tutorial" element={<Tutorial />} />
                <Route exact path="/terms" element={<Terms />} />
            </Routes>
        </div>
    );
};

export default AppRoutes;
